.modalWithHeadImg {
  width: 280px;
  padding: 0;
  .modalContent {
    position: relative;
    width: 280px;
    background: transparent;
    border-radius: 12px;
    .modalHeader {
      position: relative;
      background: transparent;
      width: 280px;
      height: 107px;
      background-image: url('https://ali.a.yximgs.com/kos/nlav12119/kFyfPzpg_2024-06-25-10-55-12.png');
      background-size: cover;
      border-radius: 12px 12px 0 0;
      padding-top: 27px;
      padding-left: 19px;

      .modalTitle {
        color: #fff;
        .titleH1 {
          text-shadow: 0 1px 1px rgba(254, 54, 102, 0.3);
          font-family: 'Alibaba PuHuiTi';
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px /* 136.364% */;
          background-clip: text;
          margin-bottom: 4px;
        }
        .titleH2 {
          color: rgba(255, 255, 255, 0.8);
          font-family: 'Alibaba PuHuiTi';
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px /* 138.462% */;
        }
      }
    }
  }
  :global {
    .clue-form-wrap-new {
      padding: 12px 16px 16px 16px;
    }
  }
}
.modalWithoutHeadImg {
  width: 280px;
  padding: 0;
  .modalContent {
    position: relative;
    width: 280px;
    border-radius: 12px;
    background-color: #fff;
    .modalHeader {
      text-align: center;
      height: 61px;
      .modalTitle {
        font-family: 'PingFang SC';
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
        padding-top: 24px;
      }
    }
  }
  :global {
    .clue-form-wrap-new {
      padding: 12px 16px 16px 16px;
    }
  }

  .tip {
    width: 248px;
    position: absolute;
    display: flex;
    margin: 0 16px;
    padding: 4px 8px;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-radius: 2px;
    background: #f8f8f8;
    color: #9c9c9c;
    font-family: 'PingFang SC';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px /* 141.667% */;
  }
}
.modalClose {
  cursor: pointer;
  vertical-align: 0;
  position: absolute;
  right: 10px;
  top: 15px;
  color: #fff;
  .modalCloseImg {
    width: 24px;
    height: 24px;
  }
}
.modalBody {
  border-radius: 0 0 12px 12px;
  padding: 12px 0 0 0;
  background-color: #fff;
  .tip {
    width: 248px;
    position: absolute;
    display: flex;
    margin: 0 16px;
    padding: 4px 8px;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-radius: 2px;
    background: #f8f8f8;
    color: #9c9c9c;
    font-family: 'PingFang SC';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px /* 141.667% */;
  }
}
.noPromopt {
  position: absolute;
  bottom: -36px;
  width: 280px;
  height: 20px;
  text-align: center;
  color: #fff;
  font-family: 'PingFang SC';
  font-size: 14;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  .whiteCircle {
    cursor: pointer;
    width: 16px;
    height: 16px;
    border: 1px solid #fff;
    border-radius: 50%;
    margin-right: 4px;
  }
  .checkedImage {
    cursor: pointer;
    object-fit: 'fill';
    width: 20px;
    height: 20px;
  }
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;