* {
  box-sizing: border-box;
  font-family: PingFang SC, Hiragino Sans GB, Microsoft YaHei, WenQuanYi Micro Hei, Helvetica Neue, Arial, sans-serif;
}
#app {
  line-height: 1.5;
  &::-webkit-scrollbar {
    display: none;
  }
  *:not(.ant-input)::-webkit-scrollbar {
    display: none;
  }
  *:not(#buttonModalText)::-webkit-scrollbar {
    display: none;
  }
}
body {
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  &::-webkit-scrollbar {
    display: none;
  }
  *:not(#buttonModalText)::-webkit-scrollbar {
    display: none;
  }
}
