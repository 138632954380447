
.Wrap {
  position: relative;
  display: flex;
  /* align-items: center; */
  width: 375px;
  /* height: 603px; */
  // height: ${(p) => (p.isEdit ? '603px' : '100vh')};
  background: #19191e;
  overflow: hidden;
  background: rgba(0, 0, 0, 1);
}

.ContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 19px;
  position: absolute;
  width: 375px;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  z-index: 99;
}

.TitleWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TitleContent {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #fff;
}


.DescWrapper {
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  overflow: hidden;
  white-space: normal !important;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;