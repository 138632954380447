.row {
  display: flex;
  margin-bottom: 16.3px;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.message {
  max-width: 263px;
  margin-left: 7.25px;
  padding: 10.87px;
  border-radius: 10.87px;
  background-color: #f6f6f6;
  border-radius: 10.87px;
  color: #222;
  color: var(--00-cs-common-text-title, #222);
  font-family: PingFang SC;
  font-size: 15.399px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.739px; /* 141.176% */
}

.cardTitle {
  font-size: 15.399px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.928px; /* 129.412% */
  margin-bottom: 4px;
}

.explain {
  margin: 7.25px 0 10.87px;
}

.explainItem {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.phone {
  display: flex;
  width: 226.449px;
  height: 43.478px;
  padding: 9.058px 10.87px;
  align-items: center;
  background-color: white;
  color: #222;

  font-size: 12.681px;
  font-style: normal;
  font-weight: 400;
  border-radius: 7.25px;
  line-height: 25.362px; /* 142.857% */
  margin-bottom: 10.87px;
}
.phoneLabel {
  margin-right: 24px;
}

.button {
  border-radius: 25.362px;
  display: flex;
  height: 36.232px;
  padding: 9.058px 14.493px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 150% */
  background-color: #fe3666;
  margin-bottom: 10.87px;
}
.agreement {
  color: #9c9c9c;
  text-align: center;
  font-size: 9.964px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.587px; /* 136.364% */
}

.link {
  color: #385080;
  margin-left: 4px;
}

.inputLine {
  width: 100%;
  margin-bottom: 10.87px;
}

.bottomFixed {
  position: fixed;
  bottom: 0;
  width: 100vw;
}

.bottomAbsolute {
  position: absolute;
  bottom: 0;
  max-width: 100%;
}

.bubbleWrapper {
  display: flex;
  padding: 0 11px;
  margin-bottom: 6px;
  overflow: scroll;
}

.bubbleItem {
  flex: none;
  border-radius: 9.058px;
  background: #f6f6f6;
  margin-right: 14.5px;
  max-width: 133px; /* 设置最大宽度 */
  white-space: nowrap; /* 确保文本在一行内显示 */
  overflow: hidden; /* 隐藏超出容器宽度的文本 */
  text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
  // display: flex;
  height: 30.797px;
  line-height: 18.115px;
  padding: 6.341px 7.246px;
  // flex-direction: column;
  // justify-content: center;
  align-items: center;
  color: #222;
  // text-align: center;
  font-size: 14px;
  font-weight: 500;
  // line-height: 20px; /* 142.857% */
}

.wrapper {
  padding: 20px 11px 0;
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;