@keyframes ani {
  0% {
    transform: scale(1); /* 开始为原始大小 */
  }
  9.4% {
    transform: scale(1.06); /* 放大1.1倍 */
  }
  18.8% {
    transform: scale(1); /* 开始为原始大小 */
  }
  28.2% {
    transform: scale(1.06); /* 放大1.1倍 */
  }
  37.6% {
    transform: scale(1); /* 开始为原始大小 */
  }
}
.wrap {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  flex-direction: column;
}

.ani {
  animation: ani 3.2s linear 1s infinite;
}

.circle {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 15px;
  font-weight: 500;
  background: #3089ff;
  // background-image: ${(p) => p.backgroundImage ?? '#3089ff'};
  color: #fff;
  font-size: 12px;
  // font-family: ${(p) => p.fontFamily ?? 'unset'};
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.circleIcon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 15px;
  font-weight: 500;
  background: #3089ff;
  background-image: #3089ff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.text {
  margin-top: 4px;
  color: #3089ff;
  font-size: 12px;
  font-weight: 500;
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;