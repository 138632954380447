.button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  white-space: nowrap;
  background-size: 100% 100%;
}


.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 280px;
  padding: 32px 0;
  border-radius: 16px;
  background-color: #fff;
  position: relative;

  .title {
    font-family: PingFang SC;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
  }

  .content {
    width: 232px;
    padding: 12px 0;
    text-align: center;
    margin-top: 16px;
    border-radius: 4px;
    background-color: #f8f8f8;
    line-height: 18px;
    font-family: PingFang SC;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }

  .desc {
    font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin-top: 8px;
    color: #666;
  }

  .exit {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 24px;
    height: 24px;
    opacity: 1;
    background: url(https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2023-02-07/1675758063045.2ecfbcd81522ab5e.png) no-repeat 0 0 / cover;
  }
}

@keyframes scaleDraw {
  /* 定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称 */
  0% {
    transform: scale(1); /* 开始为原始大小 */
  }
  9.4% {
    transform: scale(1.06); /* 放大1.1倍 */
  }
  18.8% {
    transform: scale(1); /* 开始为原始大小 */
  }
  28.2% {
    transform: scale(1.06); /* 放大1.1倍 */
  }
  37.6% {
    transform: scale(1); /* 开始为原始大小 */
  }
  // 50%{
  //     transform: scale(1);
  // }
  // 75%{
  //     transform: scale(1.1);
  // }
}
  
.dynamic {
  -webkit-animation-name: scaleDraw; /* 关键帧名称 */
  -webkit-animation-timing-function: ease-in-out; /* 动画的速度曲线 */
  -webkit-animation-iteration-count: infinite; /* 动画播放的次数 */
  -webkit-animation-duration: 3.2s; /* 动画所花费的时间 */
}



@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;