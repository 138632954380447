.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.desc {
  height: 30px;
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  color: #bfbfbf;
  background-color: #f8f8f8;
  line-height: 14px;
}

.root {
  height: 39px;
  background: #f8f8f8;
  opacity: 1;
  overflow: hidden;
  font-size: 12px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #9c9c9c;
  line-height: 15px;
  user-select: none;

  .argee {
    color: #004182;
  }
}

.agreement {
  height: 332px;
  overflow-y: scroll;
  margin: 0 24px 24px 24px;
    
  p {
    margin-bottom: 12px;
    opacity: 1;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: justify;
    color: #666;
    line-height: 21px;
  }

  h2 {
    opacity: 1;
    width: 100%;
    padding: 24px 0 0;
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: center;
    color: #222;
    line-height: 25px;
  }

  h3 {
    text-align: left;
    font-size: 16px;
    margin-bottom: 8px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #222;
    line-height: 22px;
  }
}
.customagreement {
  padding: 12px 12px;
  position: relative;
  .backWrapper {
    text-align: left;
    display: flex;
    align-items: center;
    color: #9c9c9c;
    position: absolute;
    font-size: 14px;
    line-height: 15px;
  }
  .agreementWrapper {
    height: 296px;
    padding: 0 12px;
    overflow-y: auto;
    margin-top: 22px;
    .agreementHeader {
      white-space: normal;
      overflow: auto;
      word-break: break-all;
      padding: 0 0 12px 0;
    }
    .agreementContent {
      color: #666;
      text-align: left;
      word-break: break-all;
      word-wrap: break-word;
      white-space: pre-wrap;
    }
  }
  
}

.agreementHeader {
  opacity: 1;
  font-size: 18px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  color: #222;
  line-height: 25px;
  padding: 24px 24px 12px;
  margin: 0;
}

.modelContainer {
  width: 281px;
  border-radius: 12px;
  background-color: #fff;
  overflow: hidden;
  text-align: center;
  z-index: 1007;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.4);
}

.confirmButton {
  font-size: 17px;
  width: 100%;
  opacity: 1;
  height: 48px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: center;
  border: none;
  border-top: 0.1em solid #eaeaea;
  background: white;
  color: #3089ff;
  line-height: 24px;
}

.radioLabel {
  display: inline-flex;
  align-items: center;

  .radioButton {
    // vertical-align: sub;
    padding: 0 5px 0 0;
    position: relative;
    border-radius: 50%;
    top: 2px;
    vertical-align: sub;

    input {
      width: 14px;
    }
  }
}

.round {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 43px;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 15px;
  left: 0;
  position: absolute;
  top: 0;
  width: 15px;
}

.round label:after {
  border: 1px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 4px;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: 3px;
  transform: rotate(-45deg);
  width: 7px;
}

.round input[type='checkbox'] {
  visibility: hidden;
}

.round input[type='checkbox']:checked + label {
  background-color: #3089ff;
  border-color: #3089ff;
}

.round input[type='checkbox']:checked + label:after {
  opacity: 1;
}


.structureStyle {
  background: #f0f0f0;
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;