.icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
div.rightBottom {
  color: white;
  z-index: 100000;
  height: 100%;
  padding: 0;
  // min-width: 48px;
  // min-height: 66px;
  // max-width: 56px;
  // max-height: 76px;

  // text-align: center;
  // z-index: 300;

  .customGif {
    // width: 100%;
    // height: 0;
    // padding-bottom: 100%;
    margin: 0 8px;
    background: url('./customService.gif') no-repeat;
    background-size: 100% 100%;
    background-position: center center;
  }
  .customGifStatic {
    // width: calc(100%-16px);
    // height: 0;
    // padding-bottom: calc(100%-16px);
    margin: 8px 8px 0;
    background: url('./customServiceStatic.png') no-repeat;
    background-size: 100% 100%;
    background-position: center center;
  }
  .rightIcon {
    border-radius: 50%;
    background-color: #3089ff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px 8px 0;
    // width: 100%;
    // height: 0;
    // padding-bottom: 100%;
    // width: 64px;
    // height: 64px;
    position: relative;
    // padding-top: 9px;
    // margin: 0 auto;
    svg {
      height: 46px;
      width: 46px;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  p {
    margin-bottom: 0;
    margin-top: 5px;
  }
}

@keyframes scaleDraw {
  /* 定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称 */
  0% {
    transform: scale(1); /* 开始为原始大小 */
  }
  9.4% {
    transform: scale(1.06); /* 放大1.1倍 */
  }
  18.8% {
    transform: scale(1); /* 开始为原始大小 */
  }
  28.2% {
    transform: scale(1.06); /* 放大1.1倍 */
  }
  37.6% {
    transform: scale(1); /* 开始为原始大小 */
  }
  // 50%{
  //     transform: scale(1);
  // }
  // 75%{
  //     transform: scale(1.1);
  // }
}

.dynamic {
  -webkit-animation-name: scaleDraw; /* 关键帧名称 */
  -webkit-animation-timing-function: ease-in-out; /* 动画的速度曲线 */
  -webkit-animation-iteration-count: infinite; /* 动画播放的次数 */
  -webkit-animation-duration: 3.2s; /* 动画所花费的时间 */
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1) translate(-50%, -50%);
  }

  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg) translate(-50%, -50%);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 3deg) translate(-50%, -50%);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -3deg) translate(-50%, -50%);
  }

  to {
    transform: scale3d(1, 1, 1) translate(-50%, -50%);
  }
}
.dynamicIcon {
  -webkit-animation-name: tada; /* 关键帧名称 */
  -webkit-animation-timing-function: ease-in-out; /* 动画的速度曲线 */
  -webkit-animation-iteration-count: infinite; /* 动画播放的次数 */
  -webkit-animation-duration: 3.2s; /* 动画所花费的时间 */
}

.avatarAnimation {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 48px;
    height: 49px;
    border: 1px solid #416dff;
    box-sizing: border-box;
    transform: scale(0.9);
    transform-origin: center;
    border-radius: 50%;
    backface-visibility: hidden;
    animation: avatar-animation 1.6s both infinite;
  }
}

@keyframes avatar-animation {
  0% {
    opacity: 1;
    transform: scale(0.9);
  }
  100% {
    opacity: 0;
    transform: scale(1.3);
  }
}

.bottomRichTitle {
  font-size: 14px;
  color: #666;
}

.bottomRichQuestion {
  font-size: 16px;
  color: #222;
  white-space: nowrap; /* 禁止换行 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
  width: 220px;
}

.bottomRichRoot {
  padding: 12px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  backface-visibility: hidden;
}
.avatarImg {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  border: 0.5px solid #f0f0f0;
}

.root {
  overflow: hidden;
  height: 28px;
  // position: relative;
  // padding: 4px 8px 4px 4px;
  display: inline-block;
  // width: auto;

  .wrapper {
    height: 28px;
    line-height: 28px;
    font-size: 16px;
    color: #222;
    white-space: nowrap; /* 禁止换行 */
    overflow: hidden; /* 超出部分隐藏 */
    text-overflow: ellipsis; /* 显示省略号 */
    width: 220px;
    backface-visibility: hidden;
  }
  .fadeout {
    animation: fadeout 250ms linear 0ms 1 normal both;
  }
  .fadeIn {
    animation: fadeIn 250ms linear 100ms 1 normal both;
  }
}

@keyframes fadeout {
  from {
    // opacity: 1;
    transform: translateY(0);
  }
  to {
    // opacity: 0;
    transform: translateY(-28px);
  }
}

@keyframes fadeIn {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-28px);
  }
}
.leftWrapper {
  display: flex;
  height: 48px;
}

.rightWrapper {
  width: 72px;
  height: 32px;
  flex: none;
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;