.modelContainer {
  background: #fff;
  position: absolute;
  bottom: 0;
  height: 500px;
  width: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  .titleWrapper {
    position: relative;
    // border-bottom: 1px solid #eee;
    font-weight: bold;
    text-align: center;
    padding: 24px 0 12px 0;
    font-size: 17px;
    color: #222;
    img {
      position: absolute;
      left: 10px;
      width: 22px;
      height: 22px;
    }
  }
  .contentWrapper {
    padding: 0 24px 24px 24px;
    font-size: 15px;
    line-height: 23px;
    color: #666;
    height: 360px;
    overflow: scroll;
    span {
      margin-right: 5px;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }
  .opWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;