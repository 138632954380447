.storeMembers {
    background-color: white;
    padding: 16px 0;
    margin-top: 8px;
}

.title {
    padding: 0 17px;
    font-size: 16px;
    line-height: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #222222;
    margin: 8px 0 16px 0;
}

.detail {
    display: flex;
    overflow: scroll;
}

.memberItem {
    margin-right: 8px;
    &:nth-child(1) {
        margin-left: 17px;
    }
    &:last-child {
        margin-right: 17px;
    }
}

.memberName {
    width: 108px;
    margin-top: 8px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #222222;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.memberTitle {
    width: 108px;
    margin-top: 4px;
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #666666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.memberTag {
    width: 108px;
    font-size: 10px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #9c9c9c;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.avatar {
    width: 108px;
    height: 144px;
    opacity: 1;
    // background: linear-gradient(180deg,rgba(0,0,0,0.00) 50%, rgba(0,0,0,0.32));
    border-radius: 8px;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
    }
}

.goodDetail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 12px;
}

.goodName {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #222222;
}

.goodDesc {
    margin-top: 8px;
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #9c9c9c;
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;