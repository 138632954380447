.root {
}
.icon {
    display: inline-block;
    width: 22px;
    height: 22px;
    margin-right: 8px;
}
div.rightBottom {
    position: fixed;
    width: 64px !important;
    right: 16px;
    bottom: 70px;
    color: #ff3a30;
    text-align: center;
    z-index: 300;
    .rightIcon {
        border-radius: 50%;
        background-color: #ff3a30;
        width: 64px;
        height: 64px;
        padding-top: 9px;
        margin: 0 auto;
        svg {
            height: 46px;
            width: 46px;
            display: block;
            margin: 0 auto;
        }
    }
    p {
        margin-bottom: 0;
        margin-top: 5px;
    }
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;