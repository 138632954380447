.root {
  position: relative;
  height: 400px;
  overflow: hidden;
  .contentWrapper {
    height: 300px;
    overflow: hidden;
  }
  .expandWrapper {
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      margin-top: 10px;
      width: 22.23750114440918px;
      height: 19.457813501358032px;
    }
  }
}
@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;