.root {
  word-break: break-word;
  overflow: unset;
  p {
    margin-bottom: 0;
  }
  ul {
    margin: 0;
    padding-left: 0;
    li {
      list-style: disc;
      margin: 0;
    }
  }
  ol {
    margin: 0;
    padding-left: 0;
    li {
      list-style: decimal;
      margin: 0;
      // margin-left: 40px;
    }
  }
}
.editWrapper {
  z-index: 10000000;
  pointer-events: auto;
}

.slateLeafWrapper {
  span {
    font-family: inherit;
  }
}

.flexRoot {
  display: flex;
  align-items: center;
  flex-direction: row;
}
@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;