.bottom {
    bottom: -36px;
    .toolTipArrow {
        top: 0;
        transform: translateX(-50%) rotate(180deg);
    }
}
.top {
    bottom: 28px;
    .toolTipArrow {
        bottom: -13px;
        top: 47px;
        transform: translateX(-50%);
    }
}

.toolTipBox {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    z-index: 7777;
    .toolTipArrow {
        position: relative;
        display: block;
        width: 13.07106781px;
        height: 13.07106781px;
        overflow: hidden;
        background: transparent;
        pointer-events: none;
        left: 50%;

        box-sizing: border-box;

        .toolTipArrowContent {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: block;
            width: 5px;
            height: 5px;
            margin: auto;
            background-color: rgba(0, 0, 0, 0.75);
            content: '';
            pointer-events: auto;
            transform: translateY(-6.53553391px) rotate(45deg);
            box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
        }
    }
    .toolTipText {
        min-width: 30px;
        min-height: 32px;
        padding: 6px 8px;
        color: #fff;
        text-align: left;
        text-decoration: none;
        word-wrap: break-word;
        background-color: rgba(0, 0, 0, 0.75);
        display: block;
        width: 114px;
        border-radius: 4px;
    }
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;