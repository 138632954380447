.root {
    display: flex;
    align-items: center;
    justify-content: center;

    &.bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 301;
    }
    .button {
        margin: 10px 24px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .icon {
        &::before {
            content: ' ';
            display: inline-block;
            flex-shrink: 0;
            width: 14px;
            height: 14px;
            margin-right: 7px;
            background-image: url('./icon-download.svg');
            background-size: cover;
        }
    }
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;