.root {
  padding: 32px;
  background-color: #fff;
  border-radius: 32px;
  position: relative;
  width: 100%;
  margin: 0 11px;
}

.close {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 40px;
  height: 40px;
}

.title {
  color: #222;
  font-family: "PingFang SC";
  font-size: 20px;
  font-weight: 500;
  line-height: 28px /* 140% */;
  margin-bottom: 8px;
}

.randomIconList {
    
  display: flex;
  justify-content: space-between;
  margin: 20px 2px 0;
    
}


.headImg {
  color: #9c9c9c;
  font-family: "PingFang SC";
  font-size: 15px;
  font-weight: 400;
  line-height: 17px;
  display: flex;
  align-items: center;

  .img {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 1px solid #fafafa;
    position: relative;
  }

}


.iconList {
  text-align: center;
}

.icon {
  width: 54px;
  height: 54px;
}

.iconText {
  margin-top: 4px;
  color: #000;
  font-family: "PingFang SC";
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
}

.countdownText {
  display: flex;
  align-items: center;
}

.button {
  display: flex;
  margin-top: 24px;
  justify-content: space-around;
}

.leaveBtn,.confirmBtn {
  height: 48px;
  border-radius: 24px;
  color: #222;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  padding: 12px 0;
  flex-grow: 1;
}

.leaveBtn {
  border: 1px solid #c6c6c6;
}

.confirmBtn {
  background-color: #fe3666;
  color: #fff;
  margin-left: 16px;
}


.countdown {
  display: flex;
  justify-content: center;
  margin-left: 8px;
  .timebox {
    display: flex;
    line-height: 23px;
    color: #fe3666;
    text-align: center;
    font-family: "PingFang SC";
    font-size: 15px;
    font-weight: 500;
    .timeText {
      text-align: center;
      width: 26px;
      background: rgba(254, 54, 102, 0.03);
    }
    .unit {
      margin: 0 2px;
    }
  }
}
@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;