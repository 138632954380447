.root {
  // padding-top: 20px;
  .contentWrapper {
    // margin: 12px;
    // border-radius: 8px;
    // background-color: #ccc;
    padding: 16px;
  }
  .suggestTitle {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #222;
    margin-bottom: 12px;
  }
}

.itemWrapper {
  display: flex;
  margin: 12px 0;
  .iconWrapper {
    position: relative;
    img {
      box-sizing: border-box;
      width: 65px;
      height: 85px;
      background: #f5f7f9;
      mix-blend-mode: normal;
      /* 描边/描边分隔线|stroke_divider */
      border: 1px solid #f0f0f0;
      border-radius: 8px;
      margin-right: 8px;
    }
    .top {
      position: absolute;
      top: 0;
      left: 0;
      width: 42px;
      height: 20px;
      border-top-left-radius: 8px;
      border-bottom-right-radius: 5px;
      color: #fff;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #c6c6c6;
    }
    .top1 {
      background: linear-gradient(300.38deg, #ffb000 0%, #fc0 87.37%);
    }
    .top2 {
      background: linear-gradient(135deg, #93cded 0%, #8aaee6 100%);
    }
    .top3 {
      background: linear-gradient(135deg, #f19f7a 0%, #f87339 100%);
    }
  }
  .infoWrapper {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    .name {
      font-size: 15px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 200px;
    }
  }
  .descWrapper {
    width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;