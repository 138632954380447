.button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  white-space: nowrap;
  background-size: 100% 100%;
}
.picBtnWrapper {
  height: 72px;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.descIcon {
  padding: 12px;
  display: flex;
  align-items: center;
  height: 100%;
  background-color: #fff;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .icon {
    width: 48px;
    height: 48px;
    border-radius: 8px;
  }

  .desc {
    margin: 0 8px 0 12px;
    flex-grow: 1;

    .name {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #222;
      margin-top: 4px;
    }
    .description {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #666;
    }
  }
  .button {
    // width: 86px;
    min-width: 72px;
    padding: 0 12px;
    height: 32px;
  }
}

@keyframes scaleDraw {
  /* 定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称 */
  0% {
    transform: scale(1); /* 开始为原始大小 */
  }
  9.4% {
    transform: scale(1.06); /* 放大1.1倍 */
  }
  18.8% {
    transform: scale(1); /* 开始为原始大小 */
  }
  28.2% {
    transform: scale(1.06); /* 放大1.1倍 */
  }
  37.6% {
    transform: scale(1); /* 开始为原始大小 */
  }
  // 50%{
  //     transform: scale(1);
  // }
  // 75%{
  //     transform: scale(1.1);
  // }
}

.dynamic {
  -webkit-animation-name: scaleDraw; /* 关键帧名称 */
  -webkit-animation-timing-function: ease-in-out; /* 动画的速度曲线 */
  -webkit-animation-iteration-count: infinite; /* 动画播放的次数 */
  -webkit-animation-duration: 3.2s; /* 动画所花费的时间 */
}


.dialog {
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 385px;
  border-radius: 16px 16px 0 0;
  background: #fff;
  padding: 60px 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  //   justify-content: center;
}

.wechatIcon {
  width: 90px;
  height: 90px;
  border-radius: 16px;
  margin-bottom: 16px;
}

.wechatName {
  color: #222;
  text-align: center;
  font-family: PingFang SC;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  margin-bottom: 16px;
}

.wechatDesc {
  color: #666;
  text-align: center;
  font-family: PingFang SC;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 52px;
}

.exit {
  display: flex;
  height: 48px;
  padding: 12.5px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  border: 1px solid #c6c6c6;
  color: #222;
  text-align: center;
  font-family: PingFang SC;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; 
  width: 167px;
}
@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;