.shufflingContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 2px 4px;
  color: #222;
}
.shufflingContainer > *:not(:first-child) {
  margin-left: 8px;
}
.itemContainer {
  border: 0.5px solid #d9d9d9;
  padding: 2px 4px;
  border-radius: 3px;
  // width: 100%;
  font-size: 10px;
  font-weight: 500;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}
.shufflingAvator {
  // width: 127px;
  // height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .headImg {
    color: #9c9c9c;
    font-family: "PingFang SC";
    font-size: 15px;
    font-weight: 400;
    line-height: 17px;
    display: flex;
    align-items: center;
  
    .img {
      width: 14px;
      height: 14px;
      border-radius: 10px;
      border: 1px solid #fafafa;
      position: relative;
    }
  
  }
  .shufflingText {
    font-size: 10px;
    font-weight: 500;
  }
}

.fadeoutFirst {
  animation: fadeoutFirst 250ms linear 0ms 1 normal both;
}
.fadeInMid {
  animation: fadeInMid 250ms linear 0ms 1 normal both;
}
.fadeInFour {
  animation: fadeInFour 250ms linear 0ms 1 normal both;
}
@keyframes fadeoutFirst {
  from {
    opacity: 1;
    scale: 1;

  }
  to {
    opacity: 0;
    scale: 0;

  }
}

@keyframes fadeInMid {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(8px);
  }
}
@keyframes fadeInFour {
  from {
    opacity: 0;
    transform: translateX(0);
    scale: 0;

  }
  to {
    opacity: 1;
    transform: translateX(8px);
    scale: 1;
  }
}


@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;