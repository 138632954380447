.root {
  position: relative;
  overflow-x: scroll;
}
.imageWrapper {
  display: flex;
  width: 100%;
  // justify-content: center;
  align-items: center;
  padding-left: 5%;
  .style_4_3 {
    width: 31%;
    flex-shrink: 0;
    margin-right: 1.1%;
    box-sizing: content-box;
  }
  .style_4_3:last-child {
    margin-right: 0;
  }
  .style_9_16 {
    width: 146px;
    height: 259px;
    flex-shrink: 0;
    margin-right: 7.2px;
    box-sizing: content-box;
  }
  .style_9_16:last-child {
    margin-right: 0;
  }
  
  .style_1_1 {
    width: 31%;
    flex-shrink: 0;
    margin-right: 1.1%;
    box-sizing: content-box;
  }
  .style_1_1:last-child {
    margin-right: 0;
  }
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;