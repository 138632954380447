@font-face {
    font-family: 'iconfont';
    src: url('iconfont.eot?t=1592377923466'); /* IE9 */
    src: url('iconfont.eot?t=1592377923466#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('iconfont.woff?t=1592377923466') format('woff'),
        url('iconfont.ttf?t=1592377923466') format('truetype'),
        /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */ url('iconfont.svg?t=1592377923466#iconfont')
            format('svg'); /* iOS 4.1- */
}

.iconfont-tel {
    font-family: 'iconfont' !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-phone-24-tel:before {
    content: '\e271';
}

.icon-phone-18-tel:before {
    content: '\e272';
}

.icon-phone-tel:before {
    content: '\e27c';
}
