.storeGoods {
    background-color: white;
    padding: 16px 17px;
    margin-top: 8px;
}

.title {
    font-size: 16px;
    line-height: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #222222;
    margin: 8px 0 16px 0;
}

.goodItem {
    display: flex;
    margin-bottom: 16px;
}

.avatar {
    width: 88px;
    height: 88px;
    border: 1px solid rgba(25, 31, 37, 0.1);
    border-radius: 12px;
    overflow: hidden;
    flex: none;
    img {
        width: 100%;
        height: 100%;
    }
}

.goodDetail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 12px;
}

.goodName {
    width: 240px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #222222;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
    text-overflow: ellipsis;
}

.goodDesc {
    width: 240px;
    margin-top: 8px;
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #9c9c9c;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;