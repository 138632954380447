.wrap {
  position: relative;
  display: flex;
  width: 100%;
  /* height: 603px; */
  // height: ${(p) => (p.isEdit ? '603px' : '100vh')};
  max-height: 100vh;
  background: #19191e;
  overflow: hidden;
}

.floatWrapper {
  position: absolute;
  width: 375px;
  height: 100vh;
  overflow: hidden;
  left: 0;
  top: 0;
  background: linear-gradient(
    180deg,
    rgba(25, 25, 30, 0.5) 0.1%,
    rgba(25, 25, 30, 0.14) 15%,
    rgba(25, 25, 30, 0.15) 30%,
    rgba(25, 25, 30, 0.5) 45%,
    #19191e 57%,
    #19191e 100%
  );
}

.contentWrapper {
  padding: 20px 24px;
  position: absolute;
  width: 327px;
  bottom: 68px;
  left: 24px;
  background: linear-gradient(
    360deg,
    #fff 0%,
    rgba(255, 255, 255, 0.94) 70.34%,
    rgba(255, 255, 255, 0.84) 100%
  );
  border-radius: 12px;
  z-index: 999;
}
.infoWrapper {
  display: flex;
  align-items: center;
}
.iconWrapper {
  width: 72px;
  height: 72px;
  background: #f5f7f9;
  border-radius: 11px;
  background-size: cover;
  background-repeat: no-repeat;
}
.detailWrapper {
  margin-left: 12px;
}
.titleWrapper {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #222;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.versionWrapper {
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #9c9c9c;
  display: flex;
  width: 200px;
  white-space: normal;
  word-break: break-all;
}
.descWrapper {
  margin-top: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #666;
  word-break: break-all;
  overflow: hidden;
  white-space: normal !important;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.buttonWrapper {
  margin-top: 12px;
}
@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;