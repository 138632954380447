.root {
  position: relative;
  overflow: hidden;
  height: 100%;
}
.img {
  height: 100%;
  width: 100%;
  display: block;
}
.download {
  background-color: #fff;
  margin: 10px 24px;
  position: fixed;
  z-index: 301;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  & > div {
    background-color: #3176c3;
    color: #fff;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
    height: 48px;
    line-height: 48px;
    position: relative;
    width: calc(100%);
  }

  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    width: 0%;
    height: 100%;
    opacity: 0.2;
  }
}


@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;