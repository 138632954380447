.root {
    position: fixed;
    z-index: 301;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-left: 0;

    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    box-shadow: 0px 0px 20px 1px rgba(38, 42, 59, 0.1);
    border-radius: 2px;
    border: solid 1px #d9dadd;
    background-color: #fff;
    li {
        height: 54px;
        box-sizing: content-box;

        flex-grow: 1;
        list-style: none;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 12px;
        color: #8c8e97;
        p {
            margin: 0;
            margin-top: 4px;
            max-width: calc(100% - 16px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .icon {
            width: 17px;
            height: 17px;
            margin: 0 auto;
            fill: @primary-color;
        }
    }
}

.root.style2 {
    li {
        width: 30%;
        .icon {
            fill: black;
        }
    }

    li:last-child {
        flex-direction: row;
        width: 40%;
        background-color: @primary-color;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            margin: 0;
        }
        .icon {
            fill: white;
            margin: unset;
            margin-right: 8px;
            flex-shrink: 0;
        }
    }
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;