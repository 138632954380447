.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;
  .wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .mapContainer {
      width: 341px;
      height: 120px;
      position: relative;
      overflow: hidden;
      border-radius: 4px;
      margin: 8px 0;
      .mapBlock {
        width: 100%;
        height: 180px;
        position: absolute;
        top: -30px;
      }
    }
  }
  &.drag {
    height: 100%;
    width: 100%;
  }
  background: white;
  min-width: 240px;
  max-width: 375px;
  .anchor {
    width: 60px;
    height: 60px;
    flex: 0 0 60px;
    background-image: url('./img/icon-anchor.svg');
    background-repeat: no-repeat;
  }

  .content {
    flex: 1 1 auto;

    .addressTitle {
      opacity: 1;
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #222;
      line-height: 18px;
      &.drag {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
    }
    .addressContent {
      opacity: 1;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #9c9c9c;
      line-height: 20px;
      margin-top: 4px;
      letter-spacing: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  .right {
    width: 16px;
    height: 16px;
    flex: 0 0 16px;
    background-image: url('./img/icon-arrow.svg');
    background-repeat: no-repeat;
  }
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;