.root {
  padding: 0 0 0 17px;
  background-color: white;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  line-height: 1.2;
  padding-bottom: 12px;
  height: 89px;

  .storeDetailWrapper {
    display: flex;
    justify-content: space-between;
  }
  .storeNameLine {
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 4px;
  }
  .nameText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 262px;
    display: flex;
    align-items: center;
    font-weight: 500;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .address {
    margin-left: 23px;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 276px;
  }
}
.storeItem {
  padding: 12px 17px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  .storeNameLine {
    display: flex;
    align-items: flex-end;
    margin-bottom: 4px;
  }
  .nameText {
    font-size: 16px;
    color: #222;
    line-height: 19px;
  }
  .address {
    margin-left: 23px;
    font-size: 14px;
    color: #9c9c9c;
  }
}
.showIcon {
  margin-left: 6px;
}
.storeIcon {
  margin-right: 4px;
}

.selectIcon {
  align-self: center;
}
.navIconWrapper {
  width: 59px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.navIcon {
  margin-top: 17px;
  width: 24px;
  height: 24px;
  flex: none;
}

.desc {
  font-size: 12px;
  margin-top: 4px;
}

.locationHeader {
  padding: 12px 17px;
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
}
.cityName {
  margin: 0 2px 0 4px;
}
.listContainer {
  padding-top: 19px;
  flex: 1;
  overflow: scroll;
}
.closedLabel {
  text-align: center;
  margin-left: 8px;
  width: 48px;
  height: 16px;
  opacity: 1;
  background: #ffab1a;
  border-radius: 1px;
  font-size: 10px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #fff;
  line-height: 16px;
  align-self: flex-end;
}
.storeList {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.emptyTip {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.otherTitle {
  height: 45px;
  padding: 13px 17px;
  color: #9c9c9c;
  font-size: 14px;
  border-top: 1px solid #eaeaea;
  position: sticky;
  top: -20px;
  background-color: white;
}

.tipText {
  color: #9c9c9c;
  font-size: 14px;
  margin-top: 12px;
}
.loadingStatus {
  text-align: center;
  color: #c6c6c6;
  font-size: 12px;
  margin: 16px 0;
}

.modalContainer {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: white;
  top: 0;
  left: 0;
  z-index: 1001;
}

.hidden {
  visibility: hidden;
  z-index: -1;
}

:global(.modal-no-scroll) {
  height: 100vh;
  overflow: hidden !important;
}
.updateLocation {
  height: 16px;
  display: flex;
  align-items: center;
}

.locateIcon {
  margin-left: 24px;
  margin-right: 4px;
}
.requestButton {
  font-size: 14px;
  font-weight: 400;
  color: #0075ff;
  margin-right: 8px;
}
.requestDetail {
  font-size: 12px;
  color: #c6c6c6;
}
.locateWrapper {
  display: flex;
  align-items: center;
}

.rich {
}
.detailWrapper {
  padding-bottom: 16px;
}

.storeNameWrapper {
  padding: 16px 17px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.storeName {
  font-size: 17px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 259px;
}
.singleStoreName {
  width: 100%;
}
.moreButton {
  color: #9c9c9c;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.titleContent {
  color: #666;
  font-size: 14px;
  margin-left: 5px;
  font-weight: 500;
}

.businessTime {
  margin-top: 16px;
}
.storeAddress {
  margin-top: 12px;
}
.storeAddress,
.businessTime {
  padding: 0 17px;
}
.storeAddressDetail,
.businessTimeDetail {
  font-size: 12px;
  color: #9c9c9c;
  margin-top: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.storeAddressDetail {
  width: 237px;
}
// .storeAddressDetail {
//     width: 310px;
// }

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.buttonGroup {
  display: flex;
}
.buttonItem {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
}

.buttonTitle {
  margin-top: 6px;
  font-size: 10px;
  line-height: 10px;
}

.title {
  display: flex;
  align-items: center;
}

.rightArrow {
  margin-left: 4px;
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;