.img {
    position: absolute;
    top: 0;
    left: 0;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.type3 {
    position: relative;
}
.type1 {
    position: relative;
}
.progress {
    position: absolute;
    left: 50%;
    bottom: 16px;
    transform: translateX(-50%);
    display: flex;

    .item {
        width: 24px;
        height: 3px;
        opacity: 0.6;
        background: #ffffff;
        border-radius: 2px;
        margin-right: 4px;
    }
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;