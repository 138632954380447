

.cardRoot {
  width: 341px;
  height: 65px;
  padding: 7px 11px 7px 7px;
  background: #fff;
  border-radius: 7px;
  display: flex;
  align-items: center;
}


.appIcon {
  width: 50px;
  height: 50px;
  border-radius: 6.5px;
}

.appName {
  margin: 0 7px;
  margin-right: auto;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.downloadBtn {
  width: 72px;
  height: 32px;
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;