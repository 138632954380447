.root {
  .bgContainer {
    border-radius: 8px;
    display: flex;
  }
  .richStyle {
    width: 341px;
    min-height: 93px;
    padding: 16px;
    background-image: url(./img/background-img-rich.png);
    background-size: contain;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-position: center;
    .text {
      text-align: center;
    }
    .countdown {
      display: flex;
      justify-content: center;
      margin-top: 12px;
      .timebox {
        display: flex;
        font-size: 12px;
        line-height: 28px;
        .timeText {
          width: 20px;
          height: 28px;
          text-align: center;
          line-height: 28px;
          border-radius: 4px;
          margin: 0 2px;
        }
        .unit {
          margin: 0 2px;
          font-size: 12px;
        }
      }
    }
  }
  .basicStyle {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    .textBox {
      display: flex;
      align-items: center;
      .text {
        margin-left: 4px;
      }
      .icon {
        width: 18px;
        height: 18px;
      }
    }
    .countdown {
      display: flex;
      height: 50px;
      align-items: center;
      margin-left: 8px;
      .timeBox {
        display: flex;
        line-height: 22px;
        .timeText {
          width: 24px;
          height: 22px;
          text-align: center;
          background-color: white;
          border-radius: 4px;
          line-height: 22px;
        }
        .unit {
          margin: 0 2px;
          font-size: 12px;
        }
      }
    }
  }
  .simpleStyle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 8px;
    background-color: white;
    height: 44px;
    border-radius: 8px;
    .textBox {
      display: flex;
      align-items: center;
      .text {
        margin-left: 6px;
      }
      .icon {
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px 0 0 8px;
        img {
          width: 22px;
          height: 22px;
        }
      }
    }
    .countdown {
      display: flex;
      align-items: center;
      margin-left: 8px;
      .timeBox {
        display: flex;
        line-height: 22px;
        .timeText {
          width: 24px;
          height: 22px;
          text-align: center;
          background-color: white;
          border-radius: 4px;
          line-height: 22px;
        }
        .unit {
          margin: 0 2px;
          font-size: 12px;
        }
      }
    }
  }

  .randomStyle {
    display: flex;
    justify-content: center;
    margin-left: 8px;
    .timebox {
      display: flex;
      line-height: 20px;
      color: #fe3666;
      text-align: center;
      font-family: "PingFang SC";
      font-size: 15px;
      font-weight: 500;
      .timeText {
        text-align: center;
        width: 23px;
        background: rgba(255, 214, 178, 0.1);
        border-radius: 3px;
      }
      .unit {
        margin: 0 2px;
      }
    }
  }
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;