/* stylelint-disable block-opening-brace-space-before */
/* stylelint-disable indentation */
.wrapper{
    height: 208px;
    width: 100%;
}
.cardWrapper {
  height: 208px;
  width: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  border-radius: 16px;
  margin-bottom: 34px;
  padding: 20px;
  padding-bottom: 0;
  .cardTitle{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    line-height: 16px;
  }
  .contentWrapper{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    align-items: center;
    .icon{
        width: 64px;
        height: 64px;
        border-radius: 12px;
        margin-right: 17px;
    }
    .convertTipsWrapper{
        .tips{
            font-size: 16px;
            opacity: 0.5;
            span{
                margin-right: 4px;
            }
        }
        .tips:first-child{
            margin-bottom: 15px;
            opacity: 1;
        }
        .line{
            height: 18px;
            border-left: 1px dashed #000;
            width: 1px;
            position: absolute;
            left: 108px;
            top: 78px;
            opacity: 0.3;
        }
    }
  }
}
.downloadWrapper{
    position: absolute;
    bottom: 20px;
    display: flex;
    width: 100%;
    justify-content: center;
}
.modelContainer{
    background: #fff;
    padding: 40px;
    border-radius: 16px 16px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .close{
        position: absolute;
        right: 16px;
        top: 16px;
    }
    .modelContent{
        font-weight: 400;
        font-size: 15px;
        line-height: 23px;
        color: #666;
    }
    .modelTitle{
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #000;
        margin-bottom: 10px;
    }
    .confirmButton{

        box-sizing: border-box;


        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 12.5px 24px;

        width: 256px;
        height: 48px;

        border: 1px solid #c6c6c6;
        border-radius: 24px;
        margin-top: 24px;
    }
}
@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;