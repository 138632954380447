:global(#interactive-popup-wrapper) {
    position: sticky;
    height: 0;
    top: 0;
    width: 100vw;
}
.styleWrapper {
    overflow: hidden;
}
.interactPopup {
    width: 351px;
    height: 64px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
    margin: -80px auto 8px;
    transition: margin-top 0.8s cubic-bezier(0.76, 0, 0.24, 1);
}

.animation {
    margin-top: 8px;
}

.main {
    position: relative;
    padding: 15px 16px;
    display: flex;
}

.icon {
    width: 34px;
    height: 34px;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    flex: none;
}

.guideText {
    display: flex;
    align-items: center;
    margin: 0 16px 0 12px;
    width: 169px;
    height: 36px;
    font-size: 14px;
    font-weight: 400;
    color: #222222;
    line-height: 17px;
    flex: none;
}

.button {
    width: 72px;
    height: 32px;
    opacity: 1;
    background: #3089ff;
    border: 1px solid #3089ff;
    border-radius: 17px;
    flex: none;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    // line-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.closeIconWrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    padding: 8px;
}

.closeIcon {
    position: relative;
    width: 16px;
    height: 16px;
    background: url('./close.svg') no-repeat 0 0 / cover;
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;