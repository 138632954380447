.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.desc {
  height: 30px;
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  color: #bfbfbf;
  background-color: #f8f8f8;
  line-height: 14px;
}

.modelContainer {
  width: 100vw;
}
.imageContainer {
  width: 100%;
}
.closeContainer {
  margin-top: 12px;
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
}
.closeButton {
  height: 32px;
  width: 32px;
  background: url('./close.svg');
}
@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;