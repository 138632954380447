.root {
  background: #f4f4f4;
  border-radius: 6px;
  overflow: hidden;
  padding: 8px 12px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 19px 4px;

  .wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 24px;
  }
  .fadeout {
    animation: fadeout 250ms linear 0ms 1 normal both;
  }
  .fadeIn {
    animation: fadeIn 250ms linear 100ms 1 normal both;
  }
  .avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 4px;
  }
  .desc {
    font-size: 12px;
    color: #666;
    white-space: nowrap;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-24px);
  }
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;