.progress {
  & > div {
    position: relative;
    overflow: hidden;
  }

  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    width: 0%;
    height: 100%;
    opacity: 0.2;
  }
}
.downloadBtn {
  width: 72px !important;
  height: 32px !important;
}
.secureBg {
  background: #f5f7f9;
  padding: 8px 48px;
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  color: #666;
  line-height: 14px;
  span {
    white-space: nowrap;
    color: #385080;
  }
}

.permissionDialog {
  background: #fff;
  border-radius: 12px;
  margin: 16px;
  padding: 16px 16px 0;
  margin-bottom: calc(16px + constant(safe-area-inset-bottom));
  margin-bottom: calc(16px + env(safe-area-inset-bottom));
  z-index: 333;

  h1 {
    height: 24px;
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    margin: 0;
    text-align: left;
    color: rgba(0, 5, 25, 0.85);
    line-height: 24px;
  }
  p {
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    margin: 2px 0 16px;
    text-align: left;
    color: rgba(0, 5, 25, 0.45);
    line-height: 17px;
  }
  .scroll {
    max-height: 158px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: rgba(0, 5, 25, 0.85);
    line-height: 22px;
    overflow: auto;
    .noPerm {
      margin: 0 0 32px;
    }
  }
  .dialogBottom {
    border-top: 1px solid #e0e0e0;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #3e7cf7;
    line-height: 22px;
  }
}
.appDownloadInfo {
  display: flex;
  flex-direction: row;
  padding: 12px;
  align-items: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .appIconInfo {
    width: 48px;
    height: 48px;
    // border: solid 1px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    flex-shrink: 0;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.03);
  }

  .appInfo {
    margin-left: 12px;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      margin: 0;
    }

    .appName,
    .appDesc {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .appName {
      max-width: 160px;
      height: 24px;
      font-weight: bold;
    }

    .appDesc {
      max-width: 210px;
      height: 21px;
    }
  }
}

.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 301;
}

@keyframes scaleDraw {
  /* 定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称 */
  0% {
    transform: scale(1); /* 开始为原始大小 */
  }
  9.4% {
    transform: scale(1.06); /* 放大1.1倍 */
  }
  18.8% {
    transform: scale(1); /* 开始为原始大小 */
  }
  28.2% {
    transform: scale(1.06); /* 放大1.1倍 */
  }
  37.6% {
    transform: scale(1); /* 开始为原始大小 */
  }
  // 50%{
  //     transform: scale(1);
  // }
  // 75%{
  //     transform: scale(1.1);
  // }
}

.dynamic {
  -webkit-animation-name: scaleDraw; /* 关键帧名称 */
  -webkit-animation-timing-function: ease-in-out; /* 动画的速度曲线 */
  -webkit-animation-iteration-count: infinite; /* 动画播放的次数 */
  -webkit-animation-duration: 3.2s; /* 动画所花费的时间 */
}

@keyframes sweep-shinng {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(1000px);
  }
}

// 扫光倾斜角
.dynamic2 {
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    height: 281px;
    top: -116px;
    // width: 85px;
    width: 180px;
    left: -180px;
    // left: 50px;
    z-index: 1;
    background: linear-gradient(-78deg, transparent 40%, #fff9 40%, rgba(255, 255, 255, 0.00) 67.42%, );
    animation: sweep-shinng 1.8s linear infinite;
  }
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;