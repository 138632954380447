.container {
  position: fixed;
  width: 100%;
  transition: height 0.3s ease-in-out;
  z-index: 999;
  overflow: hidden;
  top: 100vh;
  transform: translate(0,0);
  transition: transform 0.2s linear 0s;
  // padding: 6px 0 0;
  padding-top: 10px;
  background-color: rgb(248, 248, 248);
  border-radius: 16px 16px  0 0 ;
}

.titleBar {
  position: absolute;
  height: 24px; /* 设置固定高度 */
  display: flex;
  top: 0;
  width: 100vw;
  // justify-content: space-between;
  // justify-content;
  flex-direction: column;
  align-items: center;
  // background-color: #f2f2f2;
}

.iframeContainer {
  height: calc(100% - 4px); /* 100% 减去 titleBar 的高度 */
  width: 100%;
  overflow: hidden;
  iframe {
    border: none;
    display: block;
  }
}

.statusBar {
  width: 40px;
  height: 4px;
  margin-top: 6px;

  border-radius: 9.058px;
  background: #c5c5c5;

}

.cs-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}

@keyframes play-4bb95112 {
  0% {
    opacity: 1;
    transform: scale(0.9);
  }
  100% {
    opacity: 0;
    transform: scale(1.3);
  }
}  

.disableScroll {
  overflow: hidden;
  user-select: none;
}

.toggleButton {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  overflow: hidden;
  height: 20px;
  position: absolute;
}
.closeIconWrapper {
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  position: absolute;
  width: 24px;
  height: 24px;
  right: 24px;
  top: 28px;
  background: url(./closeIcon_cs.svg) no-repeat 0 0/cover ;
  backface-visibility: hidden;
  z-index: 100;
}
@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;