@font-face {
    font-family: 'iconfont2'; /* Project id  */
    src: url('iconfont.ttf?t=1621513075248') format('truetype');
}

.iconfont2 {
    font-family: 'iconfont2' !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-icon_dianhua:before {
    content: '\e604';
}
