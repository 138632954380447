@scale: calc(100vw / 375);


.aggregatePage {
  // transform: translateY(calc(100vh - @scale););
  // padding-top: calc(100vh - min(100%, 568px));
  bottom: -1vh;
  // back
  overflow: scroll;
  -webkit-overflow-scrolling: auto;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  width: 100vw;
  height: 101vh;
  z-index: 999;
  &::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }
}
.popupWrapper {
  min-height: 100vh;
  width: 100vw;
  // position: relative;
  display: flex;
  // justify-content: flex-end;
  align-items: flex-end;
  pointer-events: none;

}
.popup {
  will-change: transform;
  // transform: translateY(calc(100% - 1200px));s
  // position: absolute;
  // bottom: 0;
  // transform: translateY(calc(100vh - 568px));
  // min-height: 70vh;
  width: 100vw;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: white;
  margin-top: 150px;
  pointer-events: auto;
}
.header {
  height: 68px;
  display: flex;
  align-items: center;
  padding: 12px 20px 12px 24px;
  // border-radius: 1;
  justify-content: space-between;
  box-shadow: inset 0 -0.5px 0 #eaeaea;

}
.headerLeft {
  display: flex;
  align-items: center;
}
.storeList {
  margin-top: 4px;
  overflow: hidden;
  // min-height: 500px;
}
.successIcon {
  width: 32px;
  height: 32px;
  background: url('https://h2.static.yximgs.com/kos/nlav10749/moli-module/right.c4244c63e11a417e.png')
    no-repeat 0 0 / cover;
  margin-right: 8px;
}
.content {
  width: 264px;
}
.main {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.sub {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: 6px;
  color: #666;
}
.closeIcon {
  width: 22px;
  height: 22px;
  background: url('https://h2.static.yximgs.com/kos/nlav10749/magic-site/close-icon-three.561fe115176b5c17.png')
    no-repeat 0 0 / cover;
  margin-left: 11px;
}

.dialogCloseIcon {
  width: 40px;
  height: 40px;
  background: url('https://p1-yx.adkwai.com/kos/nlav10749/moli-module/dialogClose.3c4dd64f537c1ae3.png')
    no-repeat 0 0 / cover;
  margin-top: 24px;
}

.popupItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.left {
  display: flex;
  padding: 12px 0 12px 19px;
  align-items: center;
  &::active {
      
  }
}
.reservation {
  padding: 12px 19px 12px 0;

}
.avatar {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  margin-right: 12px;
  flex: none;
  border: 0.5px solid #f0f0f0;
  // margin-left: 16px;
}
.singleLineText {
  white-space: nowrap; /* 禁止换行 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 以省略号形式展示 */
  width: 190px;
}
.detail {
  // width: 190px;
}
.subTitle {
  margin-top: 4px;
  margin-bottom: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #9c9c9c;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.tags {
  display: flex;
}
.tag {
  margin-right: 8px;
  // display: flex;
  // text-align: center;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  width: auto;
  padding: 0 4px;

  height: 16px;

  border: 0.5px solid rgba(254, 54, 102, 0.3);
  border-radius: 3px;

  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  box-sizing: content-box;

  color: #fe3666;
}
.detail {
}
.detailTop {
  display: flex;
  align-items: center;
}
.titleLabel {
  width: 48px;
  height: 16px;
  // line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 4px;
  box-sizing: content-box;

  background: linear-gradient(95.41deg, #ee3153 0%, #fb624a 100%);
  border-radius: 3px;

  // font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  /* identical to box height, or 120% */

  text-align: center;

  color: #fff;
}
.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #222;
}
.reservationButton {
  display: flex;
  // flex-direction: row;
  justify-content: center;
  // align-items: center;
  font-size: 12px;
  width: 60px;
  height: 28px;
  line-height: 28px;
  background: #fe3666;
  border: none;
  color: white;
  border-radius: 28px;
  margin-top: 2px;
  font-weight: 500;
}
.reserved {
  opacity: 0.5;

}
.reservationNum {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #9c9c9c;
  text-align: center;
}

.inAnimation {
  // margin-top: 70vh; /* 初始位置 */
  animation-name: slide-in; /* 动画名称 */
  animation-duration: 0.5s; /* 动画时长 */
  animation-timing-function: ease-in-out;
}

.outAnimation {
  margin-top: 70vh; /* 初始位置 */
  animation-name: slide-down; /* 动画名称 */
  animation-duration: 1s; /* 动画时长 */
  animation-timing-function: ease-in-out;
}

@keyframes slide-in {
  from {
    transform: translateY(calc(min(100%, 568px) + 100px )); /* 初始位置 */
  }
  to {
    transform: translateY(0); /* 目标位置 */
  }
}

@keyframes slide-down {
  from {
    margin-top: 0; /* 初始位置 */
  }
  to {
    margin-top: 70vh; /* 目标位置 */
  }
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.emptyWrapper {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.failIcon {
  width: 96px;
  height: 96px;
  background: url('https://h2.static.yximgs.com/kos/nlav10749/moli-admin/failIcon.png') no-repeat
    0 0 / cover;
  margin-bottom: 18px;
}
.desc {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;

  text-align: center;

  color: #222;
  margin-bottom: 22px;
}
.retryButton {
  width: 112px;
  height: 40px;
  border: 1px solid #9c9c9c;
  border-radius: 32px;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  background-color: white;

  text-align: center;


  color: #222;
}

.aggregateButton {
  background: url(https://h2.static.yximgs.com/kos/nlav10749/moli-module/more-select.4702024436a21830.png) no-repeat 0 0/cover;
  width: 62px;
  height: 62px;
  position: fixed;
  top: 340px;
  right: 17px;
  z-index: 999;
}
.loading {
  // &&::after {
  //   content: '';
  //   position:absolute;
  //   top: 0;
  //   display: block;
  //   width: 100%;
  //   height: 100%;
  //   background-color: rgba(0, 0, 0, 0.3);
  // }
}
.loadMoreWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  margin-bottom: 18px;
}
.moreText {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;

  text-align: center;

  color: #9c9c9c;
}
.dropDownIcon {
  margin-left: 6px;
  display: inline-block;
  width: 11px;
  height: 6px;
  background: url(https://h2.static.yximgs.com/kos/nlav10749/moli-module/dropDown.23a0e3f60bf6222b.png) no-repeat 0 0 /cover;
}

.multiLine {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 最多展示两行文字 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; /* 超出部分用省略号表示 */
}

.aggregateDialog {
  position: relative;
  width: 312px;
  height: 429px;
  background: linear-gradient(142.55deg, #ffecd8 0.94%, #fff 44.45%, #fff 75.52%);
  border-radius: 24px;
  overflow: hidden;
  &&::after {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    display: block;
    width: 233px;
    height: 233px;
    background: url('https://p1-yx.adkwai.com/kos/nlav10749/magic-site/aggregatePageBg.ea13a9051f390532.png') no-repeat 0 0/cover;
  }
}
.dialogTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  // text-align: center;
  color: #222;
  margin-top: 24px;
  margin-left: 16px;
}
.dialogSubTitle {
  margin-top: 4px;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #9c9c9c;
  margin-left: 16px;
  margin-bottom: 12px;
}
.aggregateDialogPage {
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  // back
  overflow: scroll;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}


@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;