.baseStyle,
.bottomStyle,
.rightBottomStyle {
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.baseStyle {
    background-image: url('./img/baseStyle.png');
    display: flex;

    .title {
        width: 74%;
        padding: 12px 0 12px 20px;
    }

    .couponTitle {
        font-size: 20px;
        line-height: 28px;
        font-family: PingFangSC;
        color: #fff;
        margin-bottom: 8px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .validate {
        font-size: 12px;
        line-height: 20px;
        color: #fff;
    }

    .button {
        width: 26%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .buttonText {
        font-size: 18px;
        line-height: 24px;
        color: #e72424;
        width: 36px;
        font-weight: 600;
    }
}

.bottomStyle {
    background-image: url('./img/bottomStyle.png');
    padding: 7px 36px 7px 0;
    display: flex;

    .couponTitle {
        font-size: 17px;
        line-height: 24px;
        color: #fff;
        width: 74%;
        padding-left: 52px;
    }

    .buttonText {
        font-size: 14px;
        line-height: 23px;
        color: #e72424;
        width: 26%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.rightBottom {
    background-size: 100% 100%;
    background-image: url('./img/rightBottomStyle.png');
    background-repeat: no-repeat;
}

.dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 132px 24px 91px 24px;
    text-align: center;
    position: relative;

    .couponModal {
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 324px;
        height: 385px;
        margin-bottom: 19px;

        .title {
            // height: 124px;
            padding-top: 32px;
            height: 73px;
            box-sizing: content-box;

            .couponName {
                font-size: 20px;
                line-height: 28px;
                color: #e72424;
            }

            .countText {
                padding-top: 8px;
                font-size: 12px;
                line-height: 20px;
            }

            .businessName {
                font-size: 14px;
                line-height: 20px;
                padding: 8px 0;
            }
        }

        .logo {
            width: 52px;
            height: 52px;
            border-radius: 52px;
            margin-left: -4px;
        }

        .input {
            border: none;
            background-color: rgba(0, 0, 0, 0);
            font-size: 12px;
            line-height: 20px;
            color: #fff;
            padding-bottom: 6px;
            outline: none;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }

        .input::-webkit-input-placeholder {
            font-size: 12px;
            line-height: 20px;
            color: #fff;
        }

        .spanText {
            font-size: 12px;
            line-height: 20px;
            color: #fff;
            padding-top: 14px;
            padding-bottom: 6px;
        }

        .check {
            display: flex;
            // align-items: center;
            justify-content: space-between;
            margin: 0 39px;
        }

        .modalText {
            margin: 25px 0;
            font-size: 16px;
            line-height: 18px;
            padding: 10px 0 9px;
            // line-height: 36px;
            color: #e72424;
        }
    }

    .rule {
        font-size: 12px;
        line-height: 18px;
        color: #fff;
    }

    .successCard {
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 300px;
        height: 321px;
        margin-bottom: 30px;

        .title {
            font-size: 20px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            color: #ffffff;
            line-height: 28px;
            margin: 24px 0 12px;
        }

        .desc {
            font-size: 12px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: #ffffff;
            line-height: 17px;
        }

        .couponName {
            font-size: 20px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            color: #e72424;
            line-height: 28px;
            margin-top: 64px;
        }

        .validate {
            font-size: 12px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: #000000;
            line-height: 17px;
            margin: 12px 0 73px;
        }
    }

    .close {
        background-image: url('./img/close.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 40px;
        height: 40px;
    }
}

.ruleCard {
    background-color: #fff;
    width: 100%;
    position: absolute;
    bottom: 0;
    border-radius: 12px 12px 0 0;

    .close {
        background-image: url('./img/descClose.png');
        width: 18px;
        height: 18px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: absolute;
        top: 16px;
        right: 16px;
    }

    .title {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #222222;
        line-height: 24px;
        margin-top: 32px;
    }

    .body {
        overflow-y: scroll;
        margin: 19px 28px 27px;
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: justify;
        color: #666666;
        line-height: 20px;

        span {
            font-weight: 400;
        }
    }

    .checkBtn {
        width: 206px;
        height: 39px;
        border: 1px solid #c6c6c6;
        border-radius: 19px;
        font-size: 15px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #222222;
        line-height: 39px;
        position: absolute;
        bottom: 27px;
        left: 84px;
    }
}

.extraDialog {
    margin: 135px 24px 107px 24px;

    .simpleModal {
        // background-image: url('./img/simpleModal.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 324px;
        height: 365px;
        margin-bottom: 19px;
    }

    .simpleBusinessName {
        padding: 14px 0 11px !important;
    }
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;