.root {
  position: relative;
  height: 400px;
  overflow: hidden;
  padding: 0 17px;
  margin: -1px;

  .maxThreeLine {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .contentWrapper {
    height: 300px;
    overflow: hidden;
  }
  .expandWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    img {
      margin-top: 3px;
      width: 12px;
      height: 12px;
    }
  }

  .matrixingBox {
    width: 341px;
    position: fixed;
    z-index: -99999;
    border: 0;
    padding: 0;
    margin: 0;
    opacity: 0;
    height: 1px;
    font-size: 14;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    line-height: 1.5;
  }
}
@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;