.root {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}
.bottomBlank {
  //   padding-bottom: calc(constant(safe-area-inset-bottom) / 2);
  //   padding-bottom: calc(env(safe-area-inset-bottom) / 2);
  box-sizing: content-box;
  &::-webkit-scrollbar {
    display: initial !important;
    width: 4px;
  }

}

.fixed-bootom {
  //   background-color: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  // padding: 12px 0 calc(env(safe-area-inset-bottom) + 12px);
  padding: 12px 0;
  box-sizing: border-box;
  animation: show 0.2s ease;
  
  &.pausedAnimation {
    animation-play-state: paused;
    opacity: 0;
  }
  
  &.hide {
    animation: hide 0.2s ease;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    pointer-events: none;
    animation-play-state: running;
  }
  @keyframes hide {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;