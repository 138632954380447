.root {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  // min-width: 80px;
  // min-height: 32px;
  // max-width: 375px;
  // max-height: 72px;
  //.button {
  //    text-align: center;
  //    display: flex;
  //    align-items: center;
  //    justify-content: center;
  //}
  &.icon {
    &::before {
      content: ' ';
      display: inline-block;
      flex-shrink: 0;
      width: 14px;
      height: 14px;
      margin-right: 7px;
      background-image: url('./icons/icon-download.svg');
      background-size: cover;
    }
  }
}

@keyframes scaleDraw {
  /* 定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称 */
  0% {
    transform: scale(1); /* 开始为原始大小 */
  }
  9.4% {
    transform: scale(1.06); /* 放大1.1倍 */
  }
  18.8% {
    transform: scale(1); /* 开始为原始大小 */
  }
  28.2% {
    transform: scale(1.06); /* 放大1.1倍 */
  }
  37.6% {
    transform: scale(1); /* 开始为原始大小 */
  }
  // 50%{
  //     transform: scale(1);
  // }
  // 75%{
  //     transform: scale(1.1);
  // }
}

.dynamic {
  -webkit-animation-name: scaleDraw; /* 关键帧名称 */
  -webkit-animation-timing-function: ease-in-out; /* 动画的速度曲线 */
  -webkit-animation-iteration-count: infinite; /* 动画播放的次数 */
  -webkit-animation-duration: 3.2s; /* 动画所花费的时间 */
}

.dialog {
  width: 100%;
  position: absolute;
  bottom: 0;
  max-height: 75%;
  margin-bottom: -1px;
  border-radius: 12px 12px 0 0;
  background: #fff;
  .titlePart {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px 12px 0 0;
    background: #fff;
    margin-bottom: -1px;
    position: relative;

    p {
      margin: 0;
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #222;
      line-height: 25px;
    }

    img {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 12px;
      top: 12px;
    }
  }
}

.dialog {
  left: 0;
  position: absolute;
}


.qq-tips-wrapper {
  width: 375px;
  overflow: hidden;
  background-color: #ffeef2;
  line-height: 20px;
  color: #fe3666;
  position: fixed;
  top: 0;
  z-index: 100000;

}
.qq-tips-box {

  // word-break: keep-all;
  margin: 10px 50px 10px 18px;

  // width: fit-content;
  // animation: move 4s linear infinite;
  // // min-width: 100%;
  // white-space: nowrap;
  // display: flex;
  // align-items: center;
  overflow: hidden;
        
}
.qq-tips {
  word-break: keep-all;
  // margin: 10px 50px 10px 18px;

  // width: fit-content;
  animation: move 4s linear infinite;
  // min-width: 100%;
  white-space: nowrap;
  display: flex;
  align-items: center;
  // overflow: hidden;
  width: fit-content;
}

@keyframes move {
  0%, 20% {
    transform: translateX(0);
  }
  80%,100% {
    transform: translateX(calc(-100% + 305px));
  }
}
.close-button {
  position: absolute;
  top: 10px;
  right: 18px;
}

.buttonModal {
  margin: 0 48px;
  width: 280px;
  max-height: 347px;
  position: relative;
  border-style: solid;

  .buttonModalContent {
    width: 100%; 
    max-height: 347px;
    overflow-y: scroll;
    border-radius: 12px;

    &::-webkit-scrollbar {
      display: initial !important;
      width: 3px !important;
      color: #9c9c9c;
      // height: 10px !important;
    }
    &::-webkit-scrollbar-thumb {
      height: 5px !important;
    }
  }

  .image {
    width: 100%;
  }

  .textModal {
    padding: 24px;
  }

  .title {
    text-align: center;
    padding: 0 24px;
  }

  .text {
    max-height: 209px;
    overflow-y: scroll;
    margin-top: 12px;
    &::-webkit-scrollbar {
      display: initial !important;
      width: 3px !important;
      color: #9c9c9c;
      // height: 10px !important;
    }
    &::-webkit-scrollbar-thumb {
      height: 5px !important;
    }
  }

  .closeBtn {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
  }
}
@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;