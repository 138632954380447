.modalContainer {
  width: 100%;
  // height: 589px;
  position: fixed;
  bottom: 0;
  left: 0;
  border-radius: 16px 16px 0 0 ;
  background-color: #f8f8f8;
}
.containerTop {
  margin: 0 11px;
  .topTitleHasImg {
    display: flex;
    height: 50px;
    width: 300px;
    margin-top: 24px;
    margin-bottom: 24px;
    padding-left: 16px;
    justify-content: space-between;
    flex-direction: column;
    .topTitleText {
      color: #222;
      font-family: "PingFang SC";
      font-size: 20px;
      font-weight: 500;
      line-height: 28px /* 140% */;
    }
  }
  .topTitleNoImg {
    height: 28px;
    width: 300px;
    margin-top: 24px;
    margin-bottom: 24px;
    padding-left: 16px;
    .topTitleText {
      color: #222;
      font-family: "PingFang SC";
      font-size: 20px;
      font-weight: 500;
      line-height: 28px /* 140% */;
    }
  }
  .close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 40px;
    height: 40px;
  }
}
  
.containerProfitBigSize {
  margin: 0 11px;
  padding: 16px 28px 12px 28px;
  height: 109px;
  // width: 392px;
  background-color: #fff;
}

.containerProfitSmallSize {
  margin: 0 11px;
  padding: 12px 18px;
  height: 52px;
  border-radius: 8px;
  // line-height: 52px;
  // width: 392px;
  background-color: #fff;
}
.containerForm {
  margin: 0 11px;
  margin-top: 12px;
  background-color: #fff;
}
@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;