.navigationBarContainer {

  width: 100%;
  //   height: 44px;
  z-index: 999;
  padding: 87px 11px 0;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  border-bottom: 0.5px solid #d3d3d3;
}
// 选中的样式
.buttonTab {
  // 整体垂直定位，按钮无边框和底色 上11底下5，两个两边对齐
  flex: 1;
  margin-top: 11px;
  margin-bottom: 5px;
  padding: 0;
  border: 0;
  height: 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  .buttonTab_title {
    font-size: 17px;
    font-weight: 500;
    color: #222;
    line-height: 22px;
    width: 34px;
  }
  .buttonTab_line {
    width: 20px;
    height: 2px;
    background-color: #222;
    border-radius: 1px;
  }
}
// 未选中样式
.buttonTab_unselected {
  flex: 1;
  padding: 0;
  border: 0;
  background-color: #fff;
  .buttonTab_unselected_title {
    font-size: 16px;
    color: #737373;
    line-height: 22px;
    width: 32px;
  }
}

.structurePageTopBar {
  position: fixed;
  left: 11px;
  height: 31px;
  z-index: 1000;
  margin-top: 45px;
}
.closeBtn {
  position: fixed;
  top: 6px;
  left: 11px;
  width: 31px;
  height: 31px;
  z-index: 1000;
  margin-top: 45px;
}
.structurePageTitle {
  position: fixed;
  top: 6px;
  left: 0;
  right: 0;
  width: 100%;
  height: 31px;
  line-height: 31px;
  z-index: 1000;
  margin-top: 45px;
  font-family: PingFang SC;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color: #222;
}


@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;