.buttonIcon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  fill: #fff;
}

.rightBottom {
  // position: fixed;
  // right: 16px;
  // bottom: 70px;
  color: #02b95c;
  z-index: 100000;
  height: 100%;
  padding: 0;
  //   margin: 8px;
  // min-width: 48px;
  // min-height: 66px;
  // max-width: 56px;
  // max-height: 76px;
  // z-index: 300;

  .wechatGif {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background: url('./image/wechat.gif') no-repeat;
    background-size: 100% 100%;
    background-position: center center;
  }
  .wechatStatic {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background: url('./image/rightBottomStyle2.png') no-repeat;
    background-size: 100% 100%;
    background-position: center center;
  }

  .iconWrapper {
    border-radius: 50%;
    background-color: #02b95c;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px 8px 0;
    position: relative;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    svg {
      height: 42px;
      width: 42px;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  p {
    text-align: center;
    margin-bottom: 0;
    margin-top: 4px;
  }
}

.dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px 24px 32px 24px;
  width: 280px;
  // height: 292px;
  background-color: #fff;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.14);
  border-radius: 16px;
  text-align: center;
  position: relative;

  .exitNew {
    position: absolute;
    right: 26px;
    top: 26px;
    width: 22px;
    height: 22px;
    opacity: 1;
    background: url('https://static.yximgs.com/udata/pkg/ks-ad-fe/moli/close-new.png') no-repeat 0 0 / cover;
  }
  .exit {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 24px;
    height: 24px;
    opacity: 1;
    background: url(https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2023-02-07/1675758063045.2ecfbcd81522ab5e.png) no-repeat 0 0 / cover;
  }

  .icon {
    width: 68px;
    height: 68px;
    // fill: rgba(6, 189, 93, 1);
    border-radius: 50%;
  }

  .text {
    // color: fade(#000519, 85);
    font-size: 14px;
    margin-top: 12px;
    font-size: 17px;
    font-weight: 500;
    color: #222;
    line-height: 24px;
  }

  .account {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 232px;
    height: 42px;
    font-size: 13px;
    background-color: #f8f8f8;
    border-radius: 4px;
    text-align: center;
    color: #222;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 16px;
    font-weight: 500;
  }

  .info {
    font-size: 14px;
    color: #666;
    font-weight: 400;
    margin-top: 12px;
  }

  .close {
    width: 216px;
    height: 36px;
    background-color: #06bd5d;
    border-radius: 18px;
    margin-top: 16px;
    line-height: 36px;
    text-align: center;
    font-size: 14px;
    color: #fff;
  }

  .img {
    width: 252px;
    height: 207px;
  }
}

.qrCodedialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  width: 280px;
  height: 364px;
  background-color: #fff;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.14);
  border-radius: 18px;
  text-align: center;
  position: relative;

  .exit {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 24px;
    height: 24px;
    opacity: 1;
    background: url(https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2023-02-07/1675758063045.2ecfbcd81522ab5e.png) no-repeat 0 0 / cover;
  }

  .icon {
    width: 68px;
    height: 68px;
    fill: rgba(6, 189, 93, 1);
    border-radius: 50%;
  }

  .qrtext {
    font-size: 17px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: center;
    color: #222;
    line-height: 24px;
  }

  .shuffling {
    width: 100%;
    height: 120px;
    margin: 16px 0;
    overflow: hidden;
    border-radius: 4px;
  }

  .progressClass {
    position: absolute;
    left: 32px;
    bottom: 8px;
    display: flex;
  }

  .qrImgPart {
    height: 96px;
    width: 100%;
    display: flex;
    align-items: center;
    background: #f8f8f8;
    border-radius: 4px;
    padding: 8px;
    .qrcode {
      width: 80px;
      height: 80px;
      margin-right: 8px;
    }

    .qrdesc {
      font-size: 12px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #666;
      line-height: 22px;
      width: 144px;
    }
  }

  .close {
    width: 216px;
    height: 36px;
    background-color: #06bd5d;
    border-radius: 18px;
    margin-top: 16px;
    line-height: 36px;
    text-align: center;
    font-size: 14px;
    color: #fff;
  }
}

.pressDialog {
  width: 280px;
  background-color: #fff;
  border-radius: 12px;
  user-select: none;
  padding: 32px;

  .label {
    color: #222;
    user-select: none;
  }

  .text {
    // color: fade(#000519, 85);
    width: 190px;
    height: 24px;
    margin: 0;
    opacity: 1;
    font-size: 17px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: center;
    color: #222;
    line-height: 24px;
  }

  .selected {
    background-color: rgba(30, 118, 58, 0.24);
    position: relative;
  }

  .account {
    position: relative;
    margin: 16px 0 8px 0;
    color: #222;
    .wechat-id {
      font-weight: 500;
    }
  }
 
  .info {
    margin: 0;
  }

  .toolTip {
    position: absolute;
    top: 60px;
    left: 60%;
    transform: translateX(-50%);
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;

    .toolTipArrow {
      position: absolute;
      display: block;
      width: 13.07106781px;
      height: 13.07106781px;
      overflow: hidden;
      background: transparent;
      pointer-events: none;
      left: 50%;
      transform: translateX(-50%);
      box-sizing: border-box;
      bottom: -13px;

      .toolTipArrowContent {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 5px;
        height: 5px;
        margin: auto;
        background-color: rgba(0, 0, 0, 0.75);
        content: '';
        pointer-events: auto;
        transform: translateY(-6.53553391px) rotate(45deg);
        box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
      }
    }

    .toolTipText {
      min-width: 30px;
      min-height: 32px;
      padding: 6px 8px;
      color: #fff;
      text-align: left;
      text-decoration: none;
      word-wrap: break-word;
      background-color: rgba(0, 0, 0, 0.75);
      display: block;
      width: 114px;
      border-radius: 4px;
    }
  }
}

.jumpWxApplet {
  position: relative;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-content: center;
  width: 280px;
  //   height: 232px;
  padding: 32px 24px 32px 24px;
  background-color: #fff;
  border-radius: 12px;

  .closeIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 24px;
    height: 24px;
    background: url('https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2023-02-07/1675758063045.2ecfbcd81522ab5e.png') no-repeat 0 0 / cover;
  }

  .appletIcon {
    display: block;
    width: 68px;
    height: 68px;
    margin: 0 auto;
    background: url('./image/applet.svg') no-repeat 0 0 / cover;
  }

  .text {
    // width: 187px;
    // height: 24px;
    // opacity: 1;
    margin-top: 22px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #222;
    // line-height: 24px;
  }

  .moreInfo {
    margin: 8px 0 16px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: #666;
    line-height: 20px;
  }

  .addBtn {
    width: 216px;
    height: 36px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    line-height: 36px;
    background: #06bd5d;
    border-radius: 18px;
    margin: 0 auto;
  }

  .message {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 216px;
    height: 50px;
    color: rgba(255, 255, 255, 0.99);
    font-size: 16px;
    font-weight: 400;
    line-height: 50px;
    text-align: center;
    opacity: 0.7;
    background: #000;
    border-radius: 8px;
  }
}

.directSubscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  width: 280px;
  background-color: #fff;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.14);
  border-radius: 12px;
  text-align: center;
  position: relative;

  .info {
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: center;
    color: #222;
    line-height: 22px;
  }

  .exit {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 24px;
    height: 24px;
    opacity: 1;
    background: url(https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2023-02-07/1675758063045.2ecfbcd81522ab5e.png) no-repeat 0 0 / cover;
  }

  .close {
    width: 216px;
    height: 36px;
    background-color: #06bd5d;
    border-radius: 18px;
    margin-top: 16px;
    line-height: 36px;
    text-align: center;
    font-size: 14px;
    color: #fff;
  }

  .img {
    width: 252px;
    height: 207px;
  }
}

.appletsConfirm {
  background-color: #fff;
  padding: 24px;
  width: 280px;
  min-height: 187px;
  border-radius: 16px;
  position: absolute;
  top: 160px;

  .modalText {
    min-height: 92px;
    max-height: 230px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    overflow-y: scroll;
  }

  .buttonText,.cancelText {
    padding: 8px 12px;
    width: 108px;
    height: 36px;
    border-radius: 18px;
    text-align: center;
  }
  .cancelText {
    border: 1px solid;
    margin-right: 16px;
    line-height: 18px;
  }
}

@keyframes scaleDraw {
  /* 定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称 */
  0% {
    transform: scale(1);
    /* 开始为原始大小 */
  }

  9.4% {
    transform: scale(1.06);
    /* 放大1.1倍 */
  }

  18.8% {
    transform: scale(1);
    /* 开始为原始大小 */
  }

  28.2% {
    transform: scale(1.06);
    /* 放大1.1倍 */
  }

  37.6% {
    transform: scale(1);
    /* 开始为原始大小 */
  }

  // 50%{
  //     transform: scale(1);
  // }
  // 75%{
  //     transform: scale(1.1);
  // }
}

.dynamic {
  -webkit-animation-name: scaleDraw;
  /* 关键帧名称 */
  -webkit-animation-timing-function: ease-in-out;
  /* 动画的速度曲线 */
  -webkit-animation-iteration-count: infinite;
  /* 动画播放的次数 */
  -webkit-animation-duration: 3.2s;
  /* 动画所花费的时间 */
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;