.wrapper {
  background: #fff;
  width: 280px;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  .success {
    width: 68px;
    height: 68px;
    background: url('./success.svg');
    background-size: 100% 100%;
    margin-bottom: 20px;
  }
  .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #222;
  }
  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #666;
    margin-top: 16px;
    padding: 0 24px;
  }
  .option {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #666;
    margin-top: 16px;
  }
  .opWrapper {
    display: flex;
    .commonBtn {
      width: 100px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 18px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin-top: 24px;
    }
    .cancelBtnWrapper {
      width: 100px;
      height: 36px;
      background: #fff;
      border: 1px solid #eaeaea;
      color: #666;
      margin-right: 16px;
    }
    .sureBtnWrapper {
      background: #f73b68;
      color: #fff;

    }
  }
    
}
@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;