.base {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;

    .normal-content {
        text-align: center;
        display: block;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        text-decoration: none;
    }
}

.bottom {
    // 下期可能会做坐标调整，先保留
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    // margin: 20px auto;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;

    .normal-content {
        text-align: center;
        display: block;
        text-decoration: none;
    }
}

.right-bottom {
    position: fixed;
    width: 64px !important;
    bottom: 68px;
    right: 16px;

    div {
        width: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: space-around;
    }

    .right-bottom-content {
        text-align: center;
        display: block;
        border-radius: 32px;
        width: 64px;
        height: 64px;
        text-decoration: none;

        i {
            font-size: 43px;
            margin-left: 3px;
        }
    }

    p {
        font-size: 16px;
        color: #ff3a30;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        margin: 0;
        margin-top: 4px;
    }
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;