.Wrap {
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 100;
  // top: ${(p) => `${p.y}px`};
  // left: ${(p) => `${p.x}px`};
}
.OutCircle {
  position: absolute;
  width: 8px;
  height: 8px;
  left: -1px;
  top: -1px;
  box-sizing: content-box;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  animation: breath 2.4s linear 2s infinite;
}
@keyframes breath {
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(2);
  }
  28% {
    transform: scale(1);
  }
  42% {
    transform: scale(2);
  }
  70% {
    transform: scale(1);
  }
}
.Circle {
  position: relative;
  width: 8px;
  height: 8px;
  background: #fff;
  border: 1px solid #c6c6c6;
  border-radius: 50%;
  box-sizing: border-box;
}
.Msg {
  font-weight: 500;
  font-size: 9px;
  line-height: 14px;
  height: 16px;
  text-align: center;
  color: #fff;
  padding: 0 2px;
  margin-left: 12px;
  background: rgba(0, 0, 0, 0.4);
  ::before {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid transparent;
    border-right: 8px solid rgba(0, 0, 0, 0.4);
    left: 4px;
  }
}
.tagsWrapper {
  display: flex;
  margin-left: 2px;
  img {
    width: 9px;
    height: 16px;
    transform: translateY(50%);
  }
}
// .msgWrapper {
//   font-weight: 500;
//   line-height: 14px;
//   height: 16px;
//   text-align: center;
//   color: #fff;
//   background: rgba(0, 0, 0, 0.4);
//   display: flex;
//   align-items: center;
//   min-width: 20px;
// }
.msgWrapper {
  font-weight: 500;
  line-height: 28px;
  height: 32px;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  min-width: 40px;
  padding-right: 10px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  white-space: nowrap;
  transform: scale(0.5) translateX(-50%);
}
@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;