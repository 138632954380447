.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.img2 {
  width: 78.9333333%;
}
.type3 {
  position: relative;
  width: 100%;
  height: 100%;
  .img {
    width: 73.3%;
    height: 83.5%;
  }
}
.type1 {
  position: relative;
  width: 100%;
  height: 100%;
}
.progress {
  position: absolute;
  left: 50%;
  bottom: 16px;
  transform: translateX(-50%);
  display: flex;

  .item {
    width: 24px;
    height: 3px;
    opacity: 0.6;
    background: #fff;
    border-radius: 3px;
    margin-right: 4px;
  }
}
.group {
  display: flex;
  height: 50px;
  overflow: auto;
  align-items: center;
  padding-bottom: 8px;
  gap: 4px;
  .label {
    text-align: center;
    word-break: keep-all;
  }
  .labelWrapper {
    position: relative;
    display: block;

  }
  .labelText {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
  .labelAfter {
    display: block;
    position: absolute;
    height: 2px;
    width: 30px;
    left: 50%;
    bottom: -9px;
    right: 0;
    z-index: 1;
    border-radius: 3px;
    transform: translateX(-50%);
  }
  .labelActive {
    position: relative;
  }
}

.groupFlex {
  justify-content: space-around;
}

.groupFix {
  padding: 0 0 8px 0;
  .label {
    flex-shrink: 0;
  }
  .label:first-child {
    padding-left: 17px;
  }
  .label + .label {
    padding-left: 18px;
  }
  .label:last-child {
    padding-right: 17px;
  }
}


.imageGroup {
  height: 100%;
  .type1, .type3 {
    height: calc(100% - 50px);
  }
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;