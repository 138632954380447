.root {
    position: relative;

    video {
        width: 100%;
        max-height: 100%;
    }
    .mask,
    .replay {
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .replay > div {
        width: 185px;
        height: 36px;
        border-radius: 21px;
        color: #fff;
        text-align: center;
        font-size: 14px;
        line-height: 36px;
        background: hsla(0, 0%, 100%, 0.2);
    }
    .mask {
        .play,
        .pause {
            width: 44px;
            height: 44px;
            background-image: url('./img/play.svg');
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .pause {
            background-image: url('./img/pause.svg');
        }
        .bottom {
            width: 100%;
            height: 16px;
            position: absolute;
            bottom: 17px;
            display: flex;

            .progress {
                flex-grow: 1;
                height: 16px;
                display: flex;

                .beginTime {
                    width: 30px;
                    height: 100%;
                    font-size: 12px;
                    color: rgba(255, 255, 255, 1);
                    margin-left: 16px;
                    margin-right: 8px;
                }

                .lineWrapper {
                    position: relative;
                    display: flex;
                    align-items: center;
                    flex-grow: 1;
                    height: 100%;

                    .video-progress-line {
                        width: 1%;
                        height: 2px;
                        background: #fa6400;
                        position: relative;
                        z-index: 1;
                    }

                    .video-progress-line-control {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: -12px;
                        width: 40px;
                        height: 40px;

                        .control {
                            position: relative;
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            background: #ffffff;
                            cursor: pointer;
                        }
                    }

                    &::after {
                        background: #ffffff;
                        content: ' ';
                        z-index: 0;
                        position: absolute;
                        width: 100%;
                        height: 2px;
                    }
                }

                .totalTime {
                    width: 30px;
                    height: 100%;
                    font-size: 12px;
                    color: rgba(255, 255, 255, 1);
                    margin-left: 8px;
                }
            }

            .voice,
            .voice-muted {
                width: 16px;
                height: 16px;
                background-repeat: no-repeat;
                background-image: url('./img/voice.svg');
                background-size: 100% 100%;
                margin-left: 16px;
                cursor: pointer;
            }
            .voice-muted {
                background-image: url('./img/muted.svg');
            }

            .fullscreen {
                width: 16px;
                height: 16px;
                background-image: url('./img/full-screen.svg');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                margin-right: 16px;
                margin-left: 19px;
            }
        }
    }
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;