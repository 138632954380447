.root {
  // padding: 16px 17px;
  text-align: center;
  user-select: none;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  .desc {
    overflow-wrap: break-word;
  }

  .explicitWrapper {

  }
  .wechat {
    overflow: hidden;
    white-space: nowrap;

    .weixinhao {
      margin-left: 8px;
      font-size: 16px;
      flex-shrink: 0;
      display: inline-block;
      color: #666;
    }
    .desc {
      overflow-wrap: break-word;
      display: inline-block;
    }

    .wechatId {
      font-weight: 500;
      flex-shrink: 0;
      display: inline-block;
      position: relative;
      .toolTip {
        position: absolute;
        top: -44px;
        left: 50%;
        transform: translateX(-50%);
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        .toolTipArrow {
          position: absolute;
          display: block;
          width: 13.07106781px;
          height: 13.07106781px;
          overflow: hidden;
          background: transparent;
          pointer-events: none;
          left: 50%;
          transform: translateX(-50%);
          box-sizing: border-box;
          bottom: -13px;
          .toolTipArrowContent {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: block;
            width: 5px;
            height: 5px;
            margin: auto;
            background-color: rgba(0, 0, 0, 0.75);
            content: '';
            pointer-events: auto;
            transform: translateY(-6.53553391px) rotate(45deg);
            box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
          }
        }
        .toolTipText {
          min-width: 30px;
          min-height: 32px;
          padding: 6px 8px;
          color: #fff;
          text-align: left;
          text-decoration: none;
          word-wrap: break-word;
          background-color: rgba(0, 0, 0, 0.75);
          display: block;
          width: 114px;
          border-radius: 4px;
        }
      }
    }
  }
  .weixinIcon {
    width: 20px;
    height: 17px;
    position: relative;
    top: 2px;
  }
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;