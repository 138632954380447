.buttonIcon {
    width: 22px;
    height: 22px;
    margin-right: 5px;
    fill: #fff;
}
.rightBottom {
    position: fixed;
    width: 64px !important;
    right: 16px;
    bottom: 70px;
    color: #3ccc3b;
    text-align: center;
    z-index: 300;
    .iconWrapper {
        background-color: #3ccc3b;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        overflow: hidden;
        .rightIcon {
            margin: 0 auto;
            margin-top: 11px;
            width: 42px;
            height: 42px;

            fill: #fff;
        }
    }

    p {
        margin-bottom: 0;
        margin-top: 8px;
    }
}
.dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 32px 0 24px 0;
    width: 280px;
    height: 292px;
    background-color: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.14);
    border-radius: 18px;
    text-align: center;
    position: relative;
    .exit {
        position: absolute;
        left: 8px;
        top: 8px;
        width: 24px;
        height: 24px;
        opacity: 1;
        background: url(./dialog-close.svg) no-repeat 0 0 / cover;
    }
    .icon {
        width: 48px;
        height: 48px;
        fill: rgba(6, 189, 93, 1);
        border-radius: 50%;
    }
    .text {
        // color: fade(#000519, 85);
        font-size: 14px;
        margin-top: 8px;
        font-size: 17px;
        font-weight: 500;
        color: #222222;
    }

    .account {
        width: 232px;
        height: 44px;
        line-height: 44px;
        font-size: 16px;
        // padding: 4px 0;
        background-color: #f5f5f6;
        border-radius: 2px;
        text-align: center;
        margin-top: 24px;
        border: 1px solid #e0e0e0;
        border-radius: 2px;
        color: #222222;
    }
    .info {
        font-size: 14px;
        color: #9c9c9c;
        margin-top: 12px;
    }
    .close {
        width: 232px;
        height: 36px;
        background-color: #06bd5d;
        border-radius: 18px;
        margin-top: 24px;
        line-height: 36px;
        text-align: center;
        font-size: 14px;
        color: #fff;
    }
}

.pressDialog {
    width: 280px;
    height: 216px;
    background-color: #ffffff;
    border-radius: 12px;
    user-select: none;

    .label {
        color: #9c9c9c;
        user-select: none;
    }
    .text {
        // color: fade(#000519, 85);
        width: 190px;
    }
    .selected {
        background-color: rgba(30, 118, 58, 0.24);
        position: relative;
    }
    .account {
        position: relative;
    }
    .toolTip {
        position: absolute;
        top: -44px;
        left: 50%;
        transform: translateX(-50%);
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        .toolTipArrow {
            position: absolute;
            display: block;
            width: 13.07106781px;
            height: 13.07106781px;
            overflow: hidden;
            background: transparent;
            pointer-events: none;
            left: 50%;
            transform: translateX(-50%);
            box-sizing: border-box;
            bottom: -13px;
            .toolTipArrowContent {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: block;
                width: 5px;
                height: 5px;
                margin: auto;
                background-color: rgba(0, 0, 0, 0.75);
                content: '';
                pointer-events: auto;
                transform: translateY(-6.53553391px) rotate(45deg);
                box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
            }
        }
        .toolTipText {
            min-width: 30px;
            min-height: 32px;
            padding: 6px 8px;
            color: #fff;
            text-align: left;
            text-decoration: none;
            word-wrap: break-word;
            background-color: rgba(0, 0, 0, 0.75);
            display: block;
            width: 114px;
            border-radius: 4px;
        }
    }
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;