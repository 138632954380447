.container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: white;
  top: 0;
  left: 0;
  z-index: 1001;
}

.header {
  width: 90vw;
  font-size: 20px;
  height: 40px;
  position: absolute;
  top: 0;
  background-color: white;
  z-index: 1002;
  display: flex;
  align-items: center;
  margin: 20px 5vw;
  color: black;
}

.title {
  margin: 0 auto;
}

.hidden {
  visibility: hidden;
  z-index: -1;
}

.backIcon {
  width: 24px;
  height: 24px;
  background: url(./img/icon-arrow.svg) no-repeat 0 0 / cover;
  transform: rotate(180deg);
  position: absolute;
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;