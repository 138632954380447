.Wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #f0f0f0;
  padding: 24px;
}

.IconWrapper {
  width: 78px;
  height: 78px;
  background: #f5f7f9;
  border-radius: 12px;
  background-size: contain;
}

.NameWrapper {
  margin-top: 12px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #222;
}

.InfoWrapper {
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  align-items: center;
  color: #9c9c9c;
  margin-top: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.DescWrapper {
  width: 100%;
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  color: #666;
  white-space: normal;
  word-break: break-all;
  display: -webkit-box;
  overflow: hidden;
  white-space: normal !important;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.BtnWrapper {
  margin-top: 16px;
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;