.messageItem {
  display: flex;

  & + .messageItem {
    margin-top: 20px;
  }
}

.me {
  justify-content: flex-end;
}

.messageItemService {
  display: flex;

  .message {
    padding: 12px 16px;
  }

  //   .message::after {
  //     left: -4px;
  //     top: 14px;

  //     width: 8px;
  //     height: 8px;
  //     border-radius: 1px;
  //     border-width: 1px;
  //     border-top-color: #eaeaea;
  //     border-left-color: #eaeaea;
  //     background: white;
  //     transform: rotate(-45deg);
  //   }
}

.messageItemMe {
  display: flex;
  align-items: flex-end;

  .readed {
    color: #91919e;
    text-align: right;
    font-feature-settings: 'clig' off,
      'liga' off;
    font-family: 'PingFang SC';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    margin-right: 8px;
  }

  .message {
    // padding: 8px 12px 8px 12px;
    padding: 12px 16px;
    background-color: #618eff;
    color: #fff;
  }

  //   .message::after {
  //     border: 4px solid #95eb69;
  //     border-right-color: transparent;
  //     border-bottom-color: transparent;
  //     right: -4px;
  //     top: 14px;
  //     transform: rotate(135deg);
  //   }
}

.placeHolder {
  color: #c6c6c6;
}

.avatar {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  background-image: url(./images/avatar.png);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  background-position: center;
}

.message {
  max-width: 243px;
  overflow-wrap: break-word;
  position: relative;
  background-color: #fff;
  //   border: 1px solid #eaeaea;
  border-radius: 8px;
  color: #222;

  // height: 82px;
  // background: #ffffff;
}

// .message::after {
//   display: block;
//   position: absolute;
//   content: '';
//   border: 4px solid #fff;
//   border-right-color: transparent;
//   border-bottom-color: transparent;
// }
.serviceContainer {
  display: flex;
  height: 70px;
  align-items: center;
  background-color: #fff;
  padding-left: 19px;
  border-bottom: 0.5px solid #eaeaea;
}

.onlineAvatar {
  position: relative;
  background-image: url(./images/avatar.png);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  background-position: center;
  width: 36px;
  height: 36px;
  margin-right: 8px;
  border: 1px solid rgba(34, 34, 34, 0.1);
}

.nickName {
  color: #222;
  font-feature-settings: 'clig' off,
    'liga' off;
  font-family: 'PingFang SC';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 2px;
}

.sayStatus {
  color: #63636e;
  font-feature-settings: 'clig' off,
    'liga' off;
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
}

.onlineIcon {
  position: absolute;
  width: 14px;
  height: 14px;
  bottom: -3px;
  right: -3px;
}

.navigationBarRight {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
}

.messageBox {
  padding: 20px 16px 32px 16px;
  overflow: auto;
}

.im {
  width: 375px;
  min-height: 360px;
  background: #f8f8f8;
  position: relative;

  // z-index: 1999;
  :global {
    .clue-form-main-title {
      display: none;
    }

    .clue-form-wrap-new {
      padding: 0 19px 19px;
    }

    .clue-form-submit {
      width: 327px;
      height: 48px;
      background: #3089ff;
      border-radius: 28px;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      color: #fff !important;
      line-height: 22px;
      border: none;
      margin: 0 18.5px;
      //   margin: 8px 16px 16px 8px;
    }
  }
}

.baseButton {
  display: inline-block;
  cursor: pointer;
  touch-action: manipulation;
  padding: 4px 15px;
  outline: none;
}

.button {
  width: 161px;
  height: 40px;
  background: #3089ff;
  border-radius: 24px;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  color: #fff !important;
  border: none;
  margin: 0 16px 16px 0;
  white-space: pre-wrap;
  padding: 4px 10px;
}

.startButton {
  width: 300px;
  height: 48px;
  background: #3089ff;
  border-radius: 24px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #fff !important;
  line-height: 22px;
  border: none;
  margin: 0 auto;
}

.startButtonAnimation {
  @keyframes scaleDraw {
    /* 定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称 */
    0% {
      transform: scale(1);
      /* 开始为原始大小 */
    }

    9.4% {
      transform: scale(1.06);
      /* 放大1.1倍 */
    }

    18.8% {
      transform: scale(1);
      /* 开始为原始大小 */
    }

    28.2% {
      transform: scale(1.06);
      /* 放大1.1倍 */
    }

    37.6% {
      transform: scale(1);
      /* 开始为原始大小 */
    }

    // 50%{
    //     transform: scale(1);
    // }
    // 75%{
    //     transform: scale(1.1);
    // }
  }

  position: relative;
  overflow: hidden;
  -webkit-animation-name: scaleDraw;
  /* 关键帧名称 */
  -webkit-animation-timing-function: ease-in-out;
  /* 动画的速度曲线 */
  -webkit-animation-iteration-count: infinite;
  /* 动画播放的次数 */
  -webkit-animation-duration: 3.2s;
  /* 动画所花费的时间 */

  @keyframes inclineSweepLight {
    0% {
      transform: translateX(0);
    }

    44% {
      transform: translateX(800px);
    }

    100% {
      transform: translateX(800px);
    }
  }

  &::before {
    content: '';
    position: absolute;
    height: 281px;
    top: -116px;
    // width: 85px;
    width: 180px;
    left: -180px;
    // left: 50px;
    z-index: 1;
    background: linear-gradient(
      -78deg,
      transparent 40%,
      #ffffffc1 40%,
      rgba(255, 255, 255, 0) 67.42%
    );
    // animation: inclineSweepLight 1.8s linear infinite;
    -webkit-animation-name: inclineSweepLight;
    /* 关键帧名称 */
    -webkit-animation-timing-function: linear;
    /* 动画的速度曲线 */
    -webkit-animation-iteration-count: infinite;
    /* 动画播放的次数 */
    -webkit-animation-duration: 3.2s;
    /* 动画所花费的时间 */
  }
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.buttonBox {
  margin-bottom: 24px;
  background: #f5f5f5;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  display: flex;
  justify-content: center;

  //   &::after {
  //     content: '';
  //     display: block;
  //     position: absolute;
  //     top: -31px;
  //     width: 375px;
  //     height: 32px;
  //     background: linear-gradient(
  //       180deg,
  //       rgba(255, 255, 255, 0),
  //       rgba(248, 248, 248, 0.6) 26%,
  //       rgba(246, 246, 246, 0.9) 60%,
  //       #f5f5f5 90%
  //     );
  //     left: 0;
  //   }
  .button:nth-child(2n + 0) {
    margin-right: 0;
  }

  .button.button:nth-last-of-type(-n + 2) {
    margin-bottom: 0;
  }
}

.insertChatImStyle {
  padding-bottom: 20px;
}

.defaultChatImStyle {
  padding-bottom: 80px;
}

.fullScreenChatImStyle {
  height: 100vh;
  position: fixed;
  // overflow: scroll;
  top: 0;
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;