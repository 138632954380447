.modal {
  position: fixed;
  //   width: 100%;
  top: 40px;
  left: 0;
  z-index: 9999;
}

.rowStyle {
  height: 56px;
  overflow: hidden;
  position: relative;
  width: 375px;

    
  .rowChild {
    white-space: nowrap;
    position: absolute;
    left: 0;
    display: flex;
  }
}

.child {
  height: 24px;
  border-radius: 12px;
  background-color: rgba(0,0,0,0.4);
  color: #fff;
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px /* 150% */;
  padding: 3px 8px;
  margin-bottom: 8px;
  max-width: 234px;
  text-overflow: ellipsis;
  white-space: nowrap; 
  overflow: hidden;
  display: inline-flex;
  align-items: center;


  .icon {
    height: 16px;
    width: 16px;
    border-radius: 8px;
    margin-right: 4px;
  }
}

.fadeout {
  animation: fadeout 250ms linear 0ms 1 normal both;
}
.fadeIn {
  animation: fadeIn 250ms linear 100ms 1 normal both;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
  
@keyframes fadeIn {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-24px);
  }
}


.columnStyle {
  height: 56px;
  overflow: hidden;
  display: inline-block;
  max-width: 234px;
  margin-left: 19px;
}
@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;