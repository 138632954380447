.randomIconList {
  display: flex;
  justify-content: space-between;
  // margin: 20px 2px 0;
}
  
.iconListBigSize {
  text-align: center;
}

.iconBigSize {
  width: 54px;
  height: 54px;
}
  
.iconTextBigSize {
  margin-top: 4px;
  color: '#222222';
  font-family: "PingFang SC";
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
}

.iconListSmallSize {
  // text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.iconSmallSize {
  width: 28px;
  height: 28px;
}
  
.iconTextSmallSize {
  margin-left: 8px;
  color: '#222222';
  font-family: "PingFang SC";
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
}
@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;