.success {
  background: no-repeat url('../AppointmentModal/success.svg');
  width: 68px;
  height: 68px;
  margin: 0 auto;
  background-size: cover;
  // margin-bottom: 20px;
}
.box {
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: inherit;
  align-items: center;
  justify-content: center;
  :global {
    .wechat-id {
      color: #06bd5d;
    }
  }
}

.successText {
  font-size: 17px;
  font-weight: 500;
  color: #222;
  line-height: 24px;
  margin-top: 12px;
}
.successInfo {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #9c9c9c;
  line-height: 20px;
  margin: 8px 24px 0 24px;
}

.time {
  font-size: 14px;
  color: #666;
  line-height: 22px;
  margin-top: 8px;
}

.content {
  background-color: #fff;
  width: 280px;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 32px 0 32px 0;
  display: flex;
  flex-direction: column;
  position: relative;
}

.head {
  position: absolute;
  right: 20px;
  top: 20px;
}
.close {
  background: url('https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2023-02-07/1675758063045.2ecfbcd81522ab5e.png');
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: cover;
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;