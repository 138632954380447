.root {
  z-index: 99999;
  width: 100%;
  padding-left: 0;

  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  border-radius: 2px;
  li {
    height: 56px;
    box-sizing: content-box;
    flex: 1;
    // flex-grow: 0;
    list-style: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
    color: #8c8e97;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    p {
      margin: 0;
      margin-top: 4px;
      max-width: calc(100% - 16px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .icon {
      width: 17px;
      height: 17px;
      margin: 0 auto;
      fill: @primary-color;
    }
  }
}

.root.style2 {
  li {
    width: 30%;
    flex: auto;
    flex-grow: 0;
    .icon {
      fill: #222;
    }
  }

  li:last-child {
    width: 30%;
    flex-direction: row;
    flex-grow: 1;
    background-color: @primary-color;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding-bottom: 0px !important;

    p {
      margin: 0;
    }
    .icon {
      fill: white;
      margin: unset;
      margin-right: 4px;
      flex-shrink: 0;
    }

  }
}

.root.style3 {
  li {
    flex-grow: 0;
    flex: auto;
    width: 30%;
    .icon {
      fill: #222;
    }
  }
  li:last-child {
    flex-direction: row;
    flex-grow: 1;
    background-color: @primary-color;
    // width: 172px;
    width: 40%;
    height: 40px;
    border-radius: 20px;
    margin: 8px 16px;
    padding: 0;

    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    

    p {
      margin: 0;
    }
    .icon {
      fill: white;
      margin: unset;
      margin-right: 4px;
      flex-shrink: 0;
    }
  }
}
.root.animation {
  @keyframes scaleDraw {
    /* 定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称 */
    0% {
      transform: scale(1); /* 开始为原始大小 */
    }
    9.4% {
      transform: scale(1.06); /* 放大1.1倍 */
    }
    18.8% {
      transform: scale(1); /* 开始为原始大小 */
    }
    28.2% {
      transform: scale(1.06); /* 放大1.1倍 */
    }
    37.6% {
      transform: scale(1); /* 开始为原始大小 */
    }
    // 50%{
    //     transform: scale(1);
    // }
    // 75%{
    //     transform: scale(1.1);
    // }
  }
  li:last-child {
    -webkit-animation-name: scaleDraw; /* 关键帧名称 */
    -webkit-animation-timing-function: ease-in-out; /* 动画的速度曲线 */
    -webkit-animation-iteration-count: infinite; /* 动画播放的次数 */
    -webkit-animation-duration: 3.2s; /* 动画所花费的时间 */
  }
}

.root.animationInclineSweepLight {
  @keyframes inclineSweepLight {
    0% {
      transform: translateX(0);
    }
    44% {
      transform: translateX(var(--varIncline));
    }
    100% {
      transform: translateX(var(--varIncline));
    }
  }
  li:last-child {

    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      height: 281px;
      top: -116px;
      // width: 85px;
      width: 180px;
      left: -180px;
      // left: 50px;
      z-index: 1;
      background: linear-gradient(-78deg, transparent 40%, #ffffffc1 40%, rgba(255, 255, 255, 0.00) 67.42%, );
      // animation: inclineSweepLight 1.8s linear infinite;
      -webkit-animation-name: inclineSweepLight; /* 关键帧名称 */
      -webkit-animation-timing-function: linear; /* 动画的速度曲线 */
      -webkit-animation-iteration-count: infinite; /* 动画播放的次数 */
      -webkit-animation-duration: 3.2s; /* 动画所花费的时间 */
    }
  }
}

.structureButton {
  margin: 8px 12px !important;
  height: 44px !important;
  border-radius: 8px !important;
  flex: 1 !important;
}

.structureIcon {
  flex: 0 1 auto !important;

  p {
    overflow: visible !important;
    font-size: 10px !important;
  }
}
@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;