.base {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  // min-width: 80px;
  // min-height: 32px;
  // max-width: 375px;
  // max-height: 72px;
  &.normal-content {
    text-align: center;
    display: block;
    font-weight: 400;
    text-decoration: none;
  }
  > div {
    white-space: nowrap;
  }
}

// .right-bottom-content {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border-radius: 50%;
// }
.rightBottom {
  // position: fixed;
  // bottom: 68px;
  // right: 16px;
  z-index: 100000;
  // min-width: 48px;
  // min-height: 66px;
  // max-width: 56px;
  // max-height: 76px;
  div {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-around;
  }

  .telGif {
    // width: 100%;
    // height: 0;
    // padding-bottom: 100%;
    margin: 8px 8px 0;
    background: url('./redTel.png') no-repeat;
    background-size: 100% 100%;
    background-position: center center;
  }
  .right-bottom-content {
    // text-align: center;
    
    display: block;
    border-radius: 50%;
    // width: 64px;
    // height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 0;
    // padding-bottom: 100%;
    margin: 8px 8px 0;
    // margin-bottom: 0;
    text-decoration: none;
    position: relative;

    svg {
      font-size: 43px;
      // margin-left: 3px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    // i {
    //     font-size: 43px;
    //     // margin-left: 3px;
    //     position: absolute;
    //     left: 50%;
    //     top: 50%;
    //     transform: translate(-50%, -50%);
    // }
  }
  p {
    font-size: 16px;
    color: #ff3a30;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: center;
    margin: 0;
    margin-top: 4px;
  }
}

@keyframes scaleDraw {
  /* 定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称 */
  0% {
    transform: scale(1); /* 开始为原始大小 */
  }
  9.4% {
    transform: scale(1.06); /* 放大1.1倍 */
  }
  18.8% {
    transform: scale(1); /* 开始为原始大小 */
  }
  28.2% {
    transform: scale(1.06); /* 放大1.1倍 */
  }
  37.6% {
    transform: scale(1); /* 开始为原始大小 */
  }
  // 50%{
  //     transform: scale(1);
  // }
  // 75%{
  //     transform: scale(1.1);
  // }
}

.dynamic {
  -webkit-animation-name: scaleDraw; /* 关键帧名称 */
  -webkit-animation-timing-function: ease-in-out; /* 动画的速度曲线 */
  -webkit-animation-iteration-count: infinite; /* 动画播放的次数 */
  -webkit-animation-duration: 3.2s; /* 动画所花费的时间 */
}

@primary-color: #0075FF;@link-color: #0075FF;@success-color: #3CCC3B;@warning-color: #FFAB1A;@error-color: #FF4A4A;@font-size-base: 14px;@heading-color: rgba(0, 5, 25, 0.85);@text-color-secondary: rgba(0, 5, 25, 0.45);@disabled-color: rgba(0, 5, 25, 0.25);@border-radius-base: 4px;@border-color-base: fade(#000519,15);@box-shadow-base: 0 2px 8px rgba(0, 5, 25, 0.15);@btn-border-radius-base: 4px;